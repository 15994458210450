<template>
    <div class="hot">
        <div class="undp-content-wrapper">
            <p class="title">热门培训</p>
            <div class="top" v-if="list[0]" @click="toDetail(list[0].id)">
                <div class="cover" :style="{'backgroundImage':'url('+list[0].titleImg+')'}"></div>
                <div class="content">
                    <p class="content-title">{{ list[0].title }}</p>
                    <div class="content-other">
                        <p class="item">
                            <img src="https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/home/loc.png" height="14" width="15"/>
                            <span>{{ $Area.getText(list[0].address)}}</span>
                        </p>
                        <p class="item">
                            <img src="https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/home/time.png" height="14" width="15"/>
                            <span>{{ list[0].startTime | formatDate2 }} - {{ list[0].endTime | formatDate2 }}</span>
                        </p>
                        <p class="item">
                            <img src="https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/home/type.png" height="14" width="15"/>
                            <span>{{ list[0].departField_dictText }}</span>
                        </p>
<!--                        <input class="btn-submit" type="button" value="立即报名">-->
                    </div>
                </div>
            </div>
            <div class="bottom">
                <div class="bottom-item"  v-for="(item,i) in rightList" :key="i" @click="toDetail(item.id)">
<!--                    <img class="cover" :src="item.titleImg" />-->
                    <div class="cover" :style="{'backgroundImage':'url('+item.titleImg+')'}"></div>
                    <p class="bottom-title">{{ item.title }}</p>
                    <div class="content-other" style="padding: 0 20px;box-sizing: border-box;">
                        <p class="item">
                            <img src="https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/home/loc.png" height="14" width="15"/>
                            <span>{{ $Area.getText(item.address)}}</span>
                        </p>
                        <p class="item">
                            <img src="https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/home/time.png" height="14" width="15"/>
                            <span>{{ item.startTime | formatDate2 }} - {{ item.endTime | formatDate2 }}</span>
                        </p>
                        <p class="item">
                            <img src="https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/home/type.png" height="14" width="15"/>
                            <span>{{ item.departField_dictText }}</span>
                        </p>
<!--                        <input class="btn-submit" type="button" value="立即报名">-->
                    </div>
                </div>
            </div>
<!--            <p class="more">-->
<!--                <a href="#">加载更多</a>-->
<!--            </p>-->
        </div>
    </div>
</template>

<script>
    import {getActivityList} from '@/api/home'
    export default {
        name: "Hot",
        data() {
            return {
                list: [],
                rightList: [],
            }
        },
        mounted() {
            this.loadData()
        },
        filters: {
            formatDate2(str) {
                const date = new Date(str);
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                if(month<10){
                    month='0'+month;
                }
                var day = date.getDate();
                if(day<10){
                    day='0'+day;
                }

                return year + "/" + month + "/" + day
            }
        },
        methods: {
            toDetail(v) {
                this.$router.push('/activityDetail/'+v)
            },
            toAll() {
                this.$router.push('/tecnology')
            },
            loadData() {
                getActivityList({pageNo: 1, pageSize: 4}).then(res => {
                    if(res.success) {
                        this.list = res.result.records
                        this.rightList = this.list.slice(1)
                    }
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .hot {
        padding: 80px 0 80px;

        .title {
            height: 50px;
            font-size: 40px;
            font-weight: bold;
            line-height: 50px;
            letter-spacing: 0em;
            /* 黑色标题 */
            color: #333333;
            text-align: center;
            padding-bottom: 100px;
        }
        .top {
            display: flex;
            cursor: pointer;
            .cover {
                flex: 2;
                margin-right: 50px;
                padding-bottom: 30%;
                background-size: auto 100%;
            }
            .content {
                flex: 1;
                box-sizing: border-box;
                padding: 40px 0;
                .content-title {
                    height: 98px;
                    font-size: 32px;
                    font-weight: normal;
                    line-height: 48px;
                    letter-spacing: 0em;

                    /* 黑色标题 */
                    color: #333333;
                    padding-bottom: 30px;
                }
            }
        }
        .content-other {
            .item {
                display: flex;
                align-items: center;
                height: 26px;
                margin-bottom: 8px;
                img {
                    margin-right: 10px;
                }
            }
            .btn-submit {
                margin-top: 27px;
                width: 99px;
                height: 32px;
                border-radius: 4px;
                box-sizing: border-box;

                /* 绿2 */
                border: 1px solid #34CD8B;
                /* 立即报名 */

                font-size: 16px;
                font-weight: normal;
                line-height: 32px;
                letter-spacing: 0em;

                /* 绿2 */
                color: #34CD8B;
                background-color: #FFFFFF;
            }
        }
        .bottom {
            display: flex;
            padding-top: 50px;
            .bottom-item {
                margin-right: 50px;
                flex: 1;
                box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
                padding-bottom: 30px;
                cursor: pointer;
                .cover {
                    width: 100%;
                    padding-bottom: 60%;
                    background-size: cover;
                }
                &:nth-of-type(3n) {
                    margin-right: 0;
                }
                .bottom-title {
                    font-size: 20px;
                    font-weight: normal;
                    line-height: 30px;
                    letter-spacing: 0em;

                    /* 黑色标题 */
                    color: #333333;
                    text-align: left;
                    box-sizing: border-box;
                    padding: 10px 20px 30px;
                    box-sizing: border-box;
                    margin-bottom: 0;
                }
            }
        }
        .more {
            width: 129px;
            height: 46px;
            line-height: 46px;
            border-radius: 4px;
            /* 灰色 简介类 */
            background: #FFFFFF;
            /* 灰色 简介类 */
            border: 1px solid #999999;
            text-align: center;
            margin: 30px auto 0;
            a {
                font-size: 16px;
                font-weight: normal;
                letter-spacing: 0em;

                /* 灰色 简介类 */
                color: #999999;

            }
        }
    }

</style>