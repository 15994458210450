<template>
    <div class="tecnology-detail">

        <div class="detail-header">
            <undp-header></undp-header>
        </div>
        <div class="detail-content">
            <div class="undp-content-wrapper">
                <p class="title">{{ dt.innovateName }}</p>
                <p class="sub-title">{{ dt.innovateNameForeign }}</p>
                <div class="desc">
                    <div class="desc-wrapper">
                        <div class="item">
                            <span class="left">标识符（DOI）</span>
                            <span class="right">{{ dt.doi }}</span>
                        </div>
                        <div class="item">
                            <span class="left">所属学科</span>
                            <span class="right">{{ dt.subject }}</span>
                        </div>
                        <div class="item">
                            <span class="left">所属领域</span>
                            <span class="right">领域领域领域领域领域领域</span>
                        </div>
                        <div class="item">
                            <span class="left">资料类型</span>
                            <span class="right">117.11.JRiver.022</span>
                        </div>
                        <div class="item">
                            <span class="left">发布日期</span>
                            <span class="right">{{ dt.createTime | formatDate3 }}</span>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="module">
                        <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                        <span>内容</span>
                    </div>
                    <div v-html="dt.papers">

                    </div>
                </div>
                <div class="movies" v-if="videos.length>0">
                    <div class="module">
                        <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                        <span>视频资料</span>
                    </div>
                    <div>
                        <video v-for="(item,i) in videos" :key="i" controls="true" :src="item.url"></video>
                    </div>
                </div>
                <div class="zl">
                    <div class="module">
                        <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                        <span>文档资料</span>
                    </div>
                    <div class="zl-list">
                        <div class="zl-item" v-for="(item,i) in documents" :key="i">
                            <img class="zl-cover" src="https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/tecnology/zl.png" alt="">
                            <div class="zl-content" @click="handleToPreview(item.path)">
                                <p>{{ item.name }}</p>
                                <p>上传时间：{{ item.tm | formatDate3 }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="honer">
                    <div class="module">
                        <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                        <span>证书/专利</span>
                    </div>
                    <div class="honer-content">
                        <div class="cover" v-for="(item,i) in certificates" :key="i" :style="{'background-image': 'url('+item.url+')'}"></div>
                    </div>

                </div>
                <div class="organize">
                    <div class="module">
                        <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                        <span>研究机构</span>
                    </div>
                    <div class="org-wrapper">
                        <div class="cover"></div>
                        <div class="org-content">         清华大学（Tsinghua University），简称清华，位于北京市海屏教育区，是中华人民共和国属全国重点大学，[89]“985工程”、“211工程”计划计划、计划计划、“2011大学校长联盟”、“珠峰”、“强基”、“111大学联盟（C9亚洲联盟、松联盟、松联盟、环太平洋大学、中俄综合性大学联盟[73]--剑桥--MIT低碳大学联盟成员、中国高碳人才培养和技术研究的基地，追捧“科学工程师的摇篮”。</div>
                    </div>
                </div>
                <div class="expert" v-if="false">
                    <div class="module">
                        <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                        <span>专家团队</span>
                    </div>
                    <div class="expert-content">
                        <div class="item">
                            <div class="cover"></div>
                            <div class="expert-wrapper">
                                <p class="expert-name">江亿</p>
                                <p class="expert-desc">中国工程院院士、清华大学建筑节能研究中心主任</p>
                            </div>
                        </div>
                        <div class="item">
                            <div class="cover"></div>
                            <div class="expert-wrapper">
                                <p class="expert-name">江亿</p>
                                <p class="expert-desc">中国工程院院士、清华大学建筑节能研究中心主任</p>
                            </div>
                        </div>
                        <div class="item">
                            <div class="cover"></div>
                            <div class="expert-wrapper">
                                <p class="expert-name">江亿</p>
                                <p class="expert-desc">中国工程院院士、清华大学建筑节能研究中心主任</p>
                            </div>
                        </div>
                        <div class="item">
                            <div class="cover"></div>
                            <div class="expert-wrapper">
                                <p class="expert-name">江亿</p>
                                <p class="expert-desc">中国工程院院士、清华大学建筑节能研究中心主任</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="comment" v-if="false">
                    <div class="comment-title">
                        <p>发表评论</p>
                        <p>comment</p>
                    </div>
                    <div class="comment-ipt">
                        <textarea placeholder="发表评论"></textarea>
                        <p class="rt">
                            <input class="btn-comment" type="button" value="发表评论">
                        </p>
                    </div>
                    <div class="comment-list">
                        <div class="comment-item">
                            <div class="cover"></div>
                            <div class="comment-content">
                                <div class="top">
                                    <span>用户名</span>
                                    <span>2022-08-28 17:35</span>
                                </div>
                                <p class="bottom">评论评论评论评论评论评论评论评论评论评论评论评论评论评论评论评论</p>
                            </div>
                        </div>
                        <div class="comment-item">
                            <div class="cover"></div>
                            <div class="comment-content">
                                <div class="top">
                                    <span>用户名</span>
                                    <span>2022-08-28 17:35</span>
                                </div>
                                <p class="bottom">评论评论评论评论评论评论评论评论评论评论评论评论评论评论评论评论</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <undp-footer></undp-footer>
        </div>



    </div>
</template>

<script>
    import UndpHeader from "@/views/common/UndpHeader"
    import UndpFooter from "@/views/common/UndpFooter"
    import {getDetail} from "@/api/tecnology"
    export default {
        name: "TecnologyDetail",
        components: {
            UndpHeader, UndpFooter
        },
        data() {
          return {
              dt: {},
              videos: [],
              certificates: [],
              documents: [],
          }
        },
        filters: {
            formatDate3(str) {
                const date = new Date(str);
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                if(month<10){
                    month='0'+month;
                }
                var day = date.getDate();
                if(day<10){
                    day='0'+day;
                }

                return year +'/' + month + "/" + day
            },
        },
        mounted() {
            this.loadData()
        },
        methods: {
            loadData() {
                getDetail(this.$route.params.id).then(res => {
                    console.log('res:', res)
                    this.dt = res.result
                    if(this.dt.video) {
                        this.dt.video.split(',').forEach(v => {
                            const temp = { url: v, playing: false }
                            this.videos.push(temp)
                        })
                    }
                    if(this.dt.certificate) {
                        this.dt.certificate.split(',').forEach(v => {
                            const temp = { url: v }
                            this.certificates.push(temp)
                        })
                    }
                    if(this.dt.document) {
                        this.dt.document.split(',').forEach(v => {
                            let n = v.substring(v.lastIndexOf('/')+1,v.lastIndexOf('_'))
                            let temp = { name: n, path: v,tm: this.dt.releaseTime }
                            this.documents.push(temp)

                        })
                    }
                })
            },
            handleToPreview(path) {
                const token = this.$ls.get('Access-Token')
                if(!token) {
                    this.$message.warning('请先登录！')
                    return
                }
                this.$ls.set('path', path)
                window.open('/preview','_blank')
            }

        }
    }
</script>

<style scoped lang="less">
    .tecnology-detail {
        .detail-header {
            height: 350px;
            background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/tecnology/dtbg.png");
            background-size: cover;
        }
        .detail-content {
            position: absolute;
            left: 0;
            top: 200px;
            width: 100%;
            box-sizing: border-box;
            .undp-content-wrapper {
                background-color: #FFFFFF;
                box-sizing: border-box;
                padding: 80px 40px 40px;
                .title {
                    height: 46px;
                    font-size: 32px;
                    font-weight: normal;
                    letter-spacing: 0em;
                    /* 黑色标题 */
                    color: #333333;
                    text-align: center;
                    line-height: 46px;
                    margin-bottom: 8px;
                }
                .sub-title {
                    height: 29px;
                    font-size: 20px;
                    font-weight: normal;
                    letter-spacing: 0em;
                    line-height: 29px;
                    /* 黑色标题 */
                    color: #333333;
                    text-align: center;
                }
                .desc {
                    margin-top: 40px;
                    padding: 40px 0 20px 0;
                    border-top: 1px solid #CCCCCC;
                    border-bottom: 1px solid #CCCCCC;
                    .desc-wrapper {
                        width: 80%;
                        margin: 0 auto;
                        display: flex;
                        flex-wrap: wrap;
                        .item {
                            width: 50%;
                            height: 30px;
                            box-sizing: border-box;
                            margin-bottom: 20px;
                            .left {
                                height: 30px;
                                /* 绿2 */
                                background: #34CD8B;
                                font-size: 16px;
                                font-weight: normal;
                                line-height: 30px;
                                text-transform: uppercase;
                                letter-spacing: 0em;
                                /* White */
                                color: #FFFFFF;
                                margin-right: 20px;
                                padding: 0 5px;
                            }
                            .right {
                                height: 26px;
                                font-family: SourceHanSansCN-Regular;
                                font-size: 16px;
                                font-weight: normal;
                                line-height: 26px;
                                text-transform: uppercase;
                                letter-spacing: 0em;

                                /* 黑色标题 */
                                color: #333333;
                            }
                        }
                    }
                }
                .module {
                    display: flex;
                    align-items: center;
                    height: 30px;
                    margin-bottom: 40px;
                    span {
                        height: 30px;
                        font-family: SourceHanSansCN-Medium;
                        font-size: 20px;
                        font-weight: 500;
                        letter-spacing: 0em;
                        line-height: 30px;
                        /* 黑色标题 */
                        color: #333333;
                        margin-left: 8px;
                    }
                }
                .content {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                }
                .movies {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                }
                .zl {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                    .zl-list {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        .zl-item {
                            width: calc((100% - 60px) / 2);
                            height: 150px;
                            margin: 0 60px 20px 0;
                            background: #F5F5F5;
                            border: 1px solid rgba(0, 0, 0, 0.08);
                            display: flex;
                            align-items: center;
                            box-sizing: border-box;
                            padding: 0 30px;
                            cursor: pointer;
                            &:nth-of-type(2n) {
                                margin-right: 0;
                            }
                            .zl-cover {
                                margin-right: 30px;
                            }
                            .zl-content {
                                p:first-of-type {
                                    height: 65px;
                                    font-family: SourceHanSansCN-Regular;
                                    font-size: 20px;
                                    font-weight: normal;
                                    line-height: 30px;
                                    letter-spacing: 0em;

                                    /* 文字 */
                                    color: rgba(0, 0, 0, 0.8);
                                }
                                p:last-of-type {
                                    height: 23px;
                                    font-family: SourceHanSansCN-Regular;
                                    font-size: 16px;
                                    font-weight: normal;
                                    letter-spacing: 0em;

                                    /* 灰色 简介类 */
                                    color: #999999;
                                }
                            }
                        }
                    }
                }
                .honer {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                    .honer-content {
                        display: flex;
                        flex-wrap: wrap;
                        .cover {
                            width: calc((100% - 60px) / 4);
                            padding-bottom: 30%;
                            margin: 0 20px 20px 0;
                            background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/tecnology/zs.jpeg");
                            background-size: 100% 100%;
                            &:nth-of-type(4n) {
                                margin-right: 0;
                            }
                        }
                    }
                }
                .organize {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                    .org-wrapper {
                        display: flex;
                        align-items: center;
                        .cover {
                            width: 120px;
                            height: 120px;
                            margin-right: 50px;
                            background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/home/qh.jpeg");
                            background-size: 100% 100%;
                        }
                        .org-content {
                            flex: 1;
                        }
                    }

                }
                .expert {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                    .expert-content {
                        display: flex;
                        flex-wrap: wrap;
                        .item {
                            width: calc((100% - 120px) / 3);
                            margin: 0 60px 30px 0;
                            border: 1px solid #CCCCCC;
                            box-sizing: border-box;
                            &:nth-of-type(3n) {
                                margin-right: 0;
                            }
                            .cover {
                                width: 100%;
                                padding-bottom: 100%;
                                background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/tecnology/expert.png");
                                background-size: 100% 100%;
                            }
                            .expert-wrapper {
                                padding: 20px;
                                box-sizing: border-box;
                                background-color: #F5F5F5;
                                .expert-name {
                                    height: 30px;
                                    font-family: SourceHanSansCN-Regular;
                                    font-size: 20px;
                                    font-weight: normal;
                                    line-height: 30px;
                                    letter-spacing: 0em;
                                    /* 黑色标题 */
                                    color: #333333;
                                    margin-bottom: 15px;
                                }
                                .expert-desc {
                                    height: 52px;
                                    font-family: SourceHanSansCN-Regular;
                                    font-size: 16px;
                                    font-weight: normal;
                                    line-height: 26px;
                                    letter-spacing: 0em;

                                    /* 灰色 简介类 */
                                    color: #999999;
                                }
                            }
                        }
                    }
                }
                .comment {
                    padding: 40px 0;
                    .comment-title {
                        padding-bottom: 40px;
                        p:first-of-type {
                            height: 29px;
                            font-family: SourceHanSansCN-Medium;
                            font-size: 20px;
                            font-weight: 500;
                            letter-spacing: 0em;

                            /* 黑色标题 */
                            color: #333333;
                        }
                        p:last-of-type {
                            height: 20px;
                            font-family: SourceHanSansCN-Regular;
                            font-size: 14px;
                            font-weight: normal;
                            text-transform: uppercase;
                            letter-spacing: 0em;

                            /* 标题小字 */
                            color: #666666;
                        }
                    }
                    .comment-ipt {
                        border-top: 1px solid #CCCCCC;
                        border-bottom: 1px solid #CCCCCC;
                        box-sizing: border-box;
                        padding: 40px 0;
                        textarea {
                            width: 100%;
                            height: 84px;
                            outline: none;
                            /* 灰 */
                            background: #F5F5F5;
                            border: 1px solid rgba(0, 0, 0, 0.08);
                            padding: 20px;
                            box-sizing: border-box;
                        }
                        .btn-comment {
                            width: 80px;
                            height: 32px;
                            line-height: 32px;
                            /* 绿2 */
                            background: #34CD8B;
                            font-family: SourceHanSansCN-Regular;
                            font-size: 16px;
                            font-weight: normal;
                            letter-spacing: 0em;
                            /* White */
                            color: #FFFFFF;
                            border: none;
                            margin-top: 20px;
                        }
                    }
                    .comment-list {
                        padding-top: 20px;
                        .comment-item {
                            height: 60px;
                            display: flex;
                            align-items: center;
                            margin-bottom: 20px;
                            .cover {
                                width: 50px;
                                height: 50px;
                                background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/tecnology/head.png");
                                background-size: 100% 100%;
                                margin-right: 25px;
                            }
                            .comment-content {
                                flex: 1;
                                .top {
                                    span {
                                        &:first-of-type {
                                            height: 30px;
                                            font-family: SourceHanSansCN-Regular;
                                            font-size: 16px;
                                            font-weight: normal;
                                            line-height: 30px;
                                            letter-spacing: 0em;
                                            /* 标题小字 */
                                            color: #666666;
                                            margin-right: 20px;
                                        }
                                        &:nth-of-type(2) {
                                            height: 30px;
                                            font-family: SourceHanSansCN-Regular;
                                            font-size: 14px;
                                            font-weight: normal;
                                            line-height: 30px;
                                            letter-spacing: 0em;

                                            /* 灰色 简介类 */
                                            color: #999999;
                                        }
                                    }
                                }
                                .bottom {
                                    height: 30px;
                                    font-family: SourceHanSansCN-Regular;
                                    font-size: 16px;
                                    font-weight: normal;
                                    line-height: 30px;
                                    letter-spacing: 0em;
                                    /* 标题小字 */
                                    color: #666666;
                                }
                            }
                        }
                    }
                }
            }

        }

    }
</style>
