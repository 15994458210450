<template>
    <div class="undp-footer">
        <div class="undp-content-wrapper">
            <div class="left">
                <div class="item">
                    <p class="title">关于我们</p>
                    <div class="sub-menu">
                        <a href="#" @click="handleTo('tecnology')">创新技术</a>
                        <a href="#" @click="handleTo('project')">UNDP项目</a>
                    </div>
                </div>
                <div class="item">
                    <p class="title">资讯中心</p>
                    <div class="sub-menu">
                        <a href="#" @click="handleTo('activity')">专题活动</a>
                        <a href="#" @click="handleTo('building')">建筑报告</a>
                        <a href="#" @click="handleTo('policy')">行业政策</a>
                    </div>
                </div>
<!--                <div class="item">-->
<!--                    <p class="title">关于我们</p>-->
<!--                    <div class="sub-menu">-->
<!--                        <a href="#">创新技术</a>-->
<!--                        <a href="#">UNDP项目</a>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
<!--            <div class="right">-->
<!--                <div class="qrcode"></div>-->
<!--                <p>关注微信公众号</p>-->
<!--            </div>-->
        </div>
        <div class="undp-content-wrapper bottom">
            <p>Copyright © 2022    清华大学、青岛市建筑节能协会   版权所有</p>
            <p class="rt">技术支持：公信检测（山东）有限公司 鲁ICP备2022027888号-1</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "UndpFooter",
        methods: {
            handleTo(v) {
                this.$router.push({name: v})
            },
        }
    }
</script>

<style scoped lang="less">
    .undp-footer {
        padding: 60px 0 50px 0;
        background: rgba(0, 0, 0, 0.04);
        .undp-content-wrapper {
            display: flex;
            .left {
                flex: 1;
                display: flex;
                .item {
                    width: calc(100% / 6);
                    box-sizing: border-box;
                    padding-bottom: 20px;
                    .title {
                        width: 80px;
                        height: 26px;
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 26px;
                        letter-spacing: 0em;
                        /* 黑色标题 */
                        color: #333333;
                        border-bottom: 1px solid  #666666;
                        padding-bottom: 10px;
                    }
                    .sub-menu {
                        a {
                            display: block;
                            height: 26px;
                            font-size: 16px;
                            font-weight: normal;
                            line-height: 26px;
                            letter-spacing: 0em;

                            /* 标题小字 */
                            color: #666666;
                            margin-top: 14px;
                        }
                    }
                }
            }
            .right {
                width: 100px;
                .qrcode {
                    height: 100px;
                    background-color: #CCCCCC;
                }
                p {
                    height: 26px;
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 26px;
                    letter-spacing: 0em;

                    /* 标题小字 */
                    color: #666666;
                    margin-top: 10px;
                }
            }
        }
        .bottom {
            margin-top: 50px;
            border-top: 1px solid #CCCCCC;
            height: 50px;
            font-family: SourceHanSansCN-Regular;
            font-size: 12px;
            font-weight: normal;
            line-height: 50px;
            letter-spacing: 0em;
            /* 标题小字 */
            color: #666666;
            display: flex;
            p {
                flex: 1;
            }
        }

    }

</style>