import Vue from 'vue'
import Vuex from 'vuex'
import {ACCESS_TOKEN, UI_CACHE_DB_DICT_DATA, USER_INFO} from "@/store/mutation-types"

import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
    accessToken(state) {
      let obj = Vue.ls.get(ACCESS_TOKEN)
      if (obj) {
        return obj
      } else {
        return user.state.token
      }
    },
    realName(state) {
      let obj = Vue.ls.get(USER_INFO)
      if (obj) {
        return obj.realname
      }
      return ''
    },
    dtFormat(state) {
      let obj = Vue.ls.get(UI_CACHE_DB_DICT_DATA)
      let result = obj.data_format
      result.splice(0,0,{ value: '', title: '全部', label: '全部', text: '全部' })
      return result
    },
    dtField(state) {
      let obj = Vue.ls.get(UI_CACHE_DB_DICT_DATA)
      let result = obj.depart_field
      result.splice(0,1,{ value: '', title: '全部', label: '全部', text: '全部' })
      return result
    },
    dtType(state) {
      let obj = Vue.ls.get(UI_CACHE_DB_DICT_DATA)
      let result = obj.data_type
      result.splice(0,0,{ value: '', title: '全部', label: '全部', text: '全部' })
      return result
    },
    dtProjectType(state) {
      let obj = Vue.ls.get(UI_CACHE_DB_DICT_DATA)
      let result = obj.project_type
      result.splice(0,0,{ value: '', title: '全部', label: '全部', text: '全部' })
      return result
    },
    dtProjectState(state) {
      let obj = Vue.ls.get(UI_CACHE_DB_DICT_DATA)
      let result = obj.project_state
      result.splice(0,0,{ value: '', title: '全部', label: '全部', text: '全部' })
      return result
    },
    dtProjectBatch(state) {
      let obj = Vue.ls.get(UI_CACHE_DB_DICT_DATA)
      let result = obj.project_batch
      return result
    },
    dtActivityState(state) {
      let obj = Vue.ls.get(UI_CACHE_DB_DICT_DATA)
      let result = obj.activity_state
      result.splice(0,0,{ value: '', title: '全部', label: '全部', text: '全部' })
      return result
    },
    dtActivityType(state) {
      let obj = Vue.ls.get(UI_CACHE_DB_DICT_DATA)
      let result = obj.activity_type
      result.splice(0,0,{ value: '', title: '全部', label: '全部', text: '全部' })
      return result
    },
    dtPolicyType(state) {
      let obj = Vue.ls.get(UI_CACHE_DB_DICT_DATA)
      let result = obj.policy_type
      result.splice(0,0,{ value: '', title: '全部', label: '全部', text: '全部' })
      return result
    },
    dtBuildCategory(state) {
      let obj = Vue.ls.get(UI_CACHE_DB_DICT_DATA)
      let result = obj.build_category
      result.splice(0,0,{ value: '', title: '全部', label: '全部', text: '全部' })
      return result
    },
    dtBuildState(state) {
      let obj = Vue.ls.get(UI_CACHE_DB_DICT_DATA)
      let result = obj.build_state
      result.splice(0,0,{ value: '', title: '全部', label: '全部', text: '全部' })
      return result
    },
    dtColdMode(state) {
      let obj = Vue.ls.get(UI_CACHE_DB_DICT_DATA)
      let result = obj.cold_mode
      return result
    },
    dtThermalMode(state) {
      let obj = Vue.ls.get(UI_CACHE_DB_DICT_DATA)
      let result = obj.thermal_mode
      return result
    },
    dtPowerCompany(state) {
      let obj = Vue.ls.get(UI_CACHE_DB_DICT_DATA)
      let result = obj.power_company
      return result
    },
    dtThermalCompany(state) {
      let obj = Vue.ls.get(UI_CACHE_DB_DICT_DATA)
      let result = obj.thermal_company
      return result
    },
    dtEnergyAudit(state) {
      let obj = Vue.ls.get(UI_CACHE_DB_DICT_DATA)
      let result = obj.energy_audit
      return result
    },
    dtThermalIndex(state) {
      let obj = Vue.ls.get(UI_CACHE_DB_DICT_DATA)
      let result = obj.thermal_index
      return result
    },
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user
  }
})
