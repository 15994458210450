<template>
    <div class="tecnology-detail">
        <div class="detail-header">
            <undp-header></undp-header>
        </div>
        <div class="detail-content">
            <div class="undp-content-wrapper">
                <div class="activity-header">
                    <p class="title">{{ dt.policyName }}</p>
                </div>
                <div class="content">
                    <div class="module">
                        <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                        <span>摘要解析</span>
                    </div>
                    <div class="jx-wrapper">
<!--                        <div class="subtitle-wrapper">-->
<!--                            <span>发布机构</span><span>绿色建筑</span>-->
<!--                        </div>-->
                        <div class="subtitle-wrapper">
                            <span>发布时间</span><span>{{ dt.releaseTime | formatDate3 }}</span>
                        </div>
                        <div class="subtitle-wrapper">
                            <span>政策分类</span><span>{{ policyTypeText }}</span>
                        </div>
                        <div class="subtitle-wrapper">
                            <span>关键字</span><span>{{ dt.keyword }}</span>
                        </div>
                        <div class="subtitle-wrapper">
                            <span>发文字号</span><span>{{ dt.documentNo }}</span>
                        </div>
                        <div class="subtitle-wrapper">
                            <span>适用范围</span><span>{{ $Area.getText(dt.region) }}</span>
                        </div>
                    </div>

                </div>
                <div style="padding: 40px 0;text-indent: 2em;border-bottom: 1px solid #CCCCCC" v-html="dt.papers"></div>
                <div class="zl">
                    <div class="module">
                        <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                        <span>相关资料</span>
                    </div>
                    <div class="zl-list">
                        <div class="zl-item" v-for="(item,i) in files" :key="i">
                            <img class="zl-cover" src="https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/tecnology/zl.png" alt="">
                            <div class="zl-content" @click="handleToPreview(item.path)">
                                <p>{{ item.name }}</p>
                                <p>上传时间：{{ item.tm | formatDate3 }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <undp-footer></undp-footer>
        </div>



    </div>
</template>

<script>
    import UndpHeader from "@/views/common/UndpHeader"
    import UndpFooter from "@/views/common/UndpFooter"
    import {getDetail} from "@/api/policy"
    import { formatTime } from '@/common/util/undpUtil'
    import {mapGetters} from "vuex";
    export default {
        name: "PolicyDetail",
        components: {
            UndpHeader, UndpFooter
        },
        data() {
          return {
              dt: {},
              policyTypeText: '',
              files: []
          }
        },
        computed: {
            ...mapGetters(['dtPolicyType']),
        },
        filters: {
            formatDate3(str) {
                return formatTime(str,5)
            },
        },
        mounted() {
            this.loadData()
        },
        methods: {
            loadData() {
                const id = this.$route.params.id
                getDetail(id).then(res => {
                    console.log('res:', res)
                    this.dt = res.result
                    this.policyTypeText = this.dtPolicyType.find(v => v.value == this.dt.policyType).text
                    if(this.dt.files) {
                        this.dt.files.split(',').forEach(v => {
                            let n = v.substring(v.lastIndexOf('/')+1,v.lastIndexOf('_'))
                            let temp = { name: n, path: v,tm: this.dt.releaseTime }
                            this.files.push(temp)

                        })
                    }
                })
            },
            handleToPreview(path) {
                this.$ls.set('path', path)
                window.open('/preview','_blank')
            }

        }
    }
</script>

<style scoped lang="less">
    .tecnology-detail {
        .detail-header {
            height: 350px;
            background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/tecnology/dtbg.png");
            background-size: 100% auto;
        }
        .detail-content {
            position: absolute;
            left: 0;
            top: 200px;
            width: 100%;
            box-sizing: border-box;
            .undp-content-wrapper {
                background-color: #FFFFFF;
                box-sizing: border-box;
                padding: 80px 40px 40px;
                .activity-header {
                    padding-bottom: 40px;
                    border-bottom: 1px solid #CCCCCC;
                    .title {
                        height: 84px;
                        font-family: SourceHanSansCN-Regular;
                        font-size: 32px;
                        font-weight: normal;
                        line-height: 42px;
                        letter-spacing: 0em;
                        /* 黑色标题 */
                        color: #333333;
                        text-align: center;
                    }
                }
                .module {
                    display: flex;
                    align-items: center;
                    height: 30px;
                    margin-bottom: 40px;
                    span {
                        height: 30px;
                        font-family: SourceHanSansCN-Medium;
                        font-size: 20px;
                        font-weight: 500;
                        letter-spacing: 0em;
                        line-height: 30px;
                        /* 黑色标题 */
                        color: #333333;
                        margin-left: 8px;
                    }
                }
                .content {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                    .jx-wrapper {
                        width: 80%;
                        margin: 0 auto;
                        height: 100px;
                        display: flex;
                        flex-wrap: wrap;
                        .subtitle-wrapper {
                            width: calc((100% - 40px) / 3);
                            margin-bottom: 10px;
                            &:nth-of-type(3n) {
                                margin-right: 0;
                            }
                            span:first-of-type {
                                display: inline-block;
                                padding: 0 8px;
                                width: 80px;
                                height: 30px;
                                /* 绿2 */
                                background: #34CD8B;
                                /* 关联领域 */
                                font-size: 16px;
                                font-weight: normal;
                                line-height: 30px;
                                text-transform: uppercase;
                                letter-spacing: 0em;

                                /* White */
                                color: #FFFFFF;
                                margin-right: 20px;
                            }
                        }
                    }

                }
                .zl {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                    .zl-list {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        .zl-item {
                            width: calc((100% - 60px) / 2);
                            height: 150px;
                            margin: 0 60px 20px 0;
                            background: #F5F5F5;
                            border: 1px solid rgba(0, 0, 0, 0.08);
                            display: flex;
                            align-items: center;
                            box-sizing: border-box;
                            padding: 0 30px;
                            cursor: pointer;
                            &:nth-of-type(2n) {
                                margin-right: 0;
                            }
                            .zl-cover {
                                margin-right: 30px;
                            }
                            .zl-content {
                                p:first-of-type {
                                    height: 65px;
                                    font-family: SourceHanSansCN-Regular;
                                    font-size: 20px;
                                    font-weight: normal;
                                    line-height: 30px;
                                    letter-spacing: 0em;

                                    /* 文字 */
                                    color: rgba(0, 0, 0, 0.8);
                                }
                                p:last-of-type {
                                    height: 23px;
                                    font-family: SourceHanSansCN-Regular;
                                    font-size: 16px;
                                    font-weight: normal;
                                    letter-spacing: 0em;

                                    /* 灰色 简介类 */
                                    color: #999999;
                                }
                            }
                        }
                    }
                }
            }

        }

    }
</style>