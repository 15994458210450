<template>
    <div class="undp-header">
        <div class="undp-content-wrapper">
            <div class="left">
                零碳技术共享平台
            </div>
            <div class="middle">
                <a href="#" class="menu" @click="handleTo('home')">首页</a>
                <a href="#" class="menu" @click="handleTo('tecnology')">创新技术</a>
                <a href="#" class="menu" @click="handleTo('project')">UNDP项目</a>
                <a href="#" class="menu" @click="handleTo('activity')">专题活动</a>
                <a href="#" class="menu" @click="handleTo('policy')">行业政策</a>
                <a href="#" class="menu" @click="handleTo('building')">建筑报告</a>
            </div>
            <div class="right">
                <template v-if="realName">
                    <span class="userinfo">欢迎您：{{ realName }}</span>
                    <a href="#" class="to-login" @click="handleLogout">退出</a>
                </template>
                <a v-else href="#" class="to-login" @click="handleToLogin">登录</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "UndpHeader",
        data() {
            return {
                realName: ''
            }
        },
        mounted() {
            this.realName = this.$ls.get('realname')
        },
        methods: {
            handleTo(v) {
                this.$router.push({name: v})
            },
            handleToLogin() {
                this.$router.push('/login')
            },
            handleLogout() {
                this.realName = ''
                this.$store.dispatch('Logout').then(res => {
                    this.$router.push('/home')
                })
            }
        }
    }
</script>

<style scoped lang="less">

    .undp-header {
        height: 84px;
        .undp-content-wrapper {
            height: 100%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            .left {
                /* UNDP | 住建部 | 清华大学 */
                font-size: 20px;
                font-weight: bold;
                letter-spacing: 0em;
                /* White */
                color: #666666;
                width: 300px;
            }
            .middle {
                flex: 1;
                height: 100%;
                .menu {
                    display: inline-block;
                    height: 100%;
                    line-height: 84px;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0em;
                    /* White */
                    color: #666666;
                    margin-right: 55px;
                    text-align: center;
                    text-decoration: none;
                    box-sizing: border-box;
                    &.active {
                        border-bottom: 3px solid #34CD8B;
                    }
                    &:hover {
                        border-bottom: 3px solid #34CD8B;
                    }
                }
            }
            .right {
                width: 200px;
                display: flex;
                justify-content: end;
                .to-login {
                    width: 75px;
                    line-height: 32px;
                    text-align: center;
                    border-radius: 8px;
                    box-sizing: border-box;
                    /* White */
                    border: 1px solid #666666;
                    color: #666666;

                }
                .userinfo {
                    line-height: 32px;
                    margin-right: 20px;
                }
            }
        }
    }

</style>
