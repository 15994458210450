<template>
    <div class="home">
        <div class="home-header">
            <undp-header></undp-header>
            <div class="title-wrapper">
                公共建筑节能低碳技术分享平台
            </div>
            <div class="subtitle">信息传播 - 技术共享 - 成果转化</div>
<!--            <div class="search-container">-->
<!--                <div class="search-wrapper">-->
<!--                    <div class="search-tp">-->
<!--                        <a href="#" class="search-type">-->
<!--                            <span class="mr6">创新技术</span><img src="https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/home/arrowdown.png" height="7" width="10"/></a>-->
<!--                    </div>-->

<!--                    <input type="text" class="ipt">-->
<!--                    <a-button class="search" type="primary" icon="search" />-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="hot-wrapper">-->
<!--                <span>热门搜索：</span>-->
<!--                <a href="#">清洁能源</a>-->
<!--                <a href="#">双碳政策</a>-->
<!--                <a href="#">碳汇交易</a>-->
<!--            </div>-->
        </div>
        <div class="home-banner">
            <div class="banner-wrapper">
                <div class="title">碳中和低碳技术资源站</div>
                <div class="subtitle">网罗国际先进技术 共同实现双碳规划</div>
                <a href="#" class="more" @click="$router.push({name:'tecnology'})">了解更多--</a>
            </div>
        </div>
        <latest-tec></latest-tec>
        <hot></hot>
        <latest-policy></latest-policy>
<!--        <servicer></servicer>-->
    </div>
</template>

<script>
    import UndpHeader from "@/views/common/UndpHeader"
    import LatestTec from "@/views/home/component/LatestTec"
    import Hot from "@/views/home/component/Hot"
    import LatestPolicy from "@/views/home/component/LatestPolicy"
    // import Servicer from "@/views/home/component/Servicer"
    export default {
        name: "Home",
        components: {
            UndpHeader, LatestTec, Hot, LatestPolicy
        }
    }
</script>

<style scoped lang="less">
    .home {
        .home-header {
            width: 100%;
            height: 500px;
            background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/home/bg1.png");
            background-size: 100% 100%;
            .title-wrapper {
                padding-top: 80px;
                font-family: SourceHanSansCN-Bold;
                font-size: 64px;
                font-weight: bold;
                letter-spacing: 0em;
                /* White */
                color: #FFFFFF;
                text-align: center;
            }
            .subtitle {
                font-family: SourceHanSansCN-Bold;
                font-size: 48px;
                font-weight: bold;
                letter-spacing: 0em;

                /* White */
                color: #FFFFFF;
                text-align: center;
                padding: 30px 0 50px 0;
            }
            .search-container {
                .search-wrapper {
                    width: 618px;
                    height: 42px;
                    line-height: 42px;
                    background: #FFFFFF;
                    margin: 0 auto;
                    box-sizing: border-box;
                    display: flex;
                    border-radius: 0px 4px 4px 0px;
                    overflow: hidden;
                    .search-tp {
                        position: relative;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        padding: 0 20px;
                        .search-type {
                            border-right: 1px solid #CCCCCC;
                            padding-right: 10px;
                            font-size: 18px;
                            font-weight: normal;
                            letter-spacing: 0px;
                            /* 标题小字 */
                            color: #666666;
                            display: flex;
                            align-items: center;
                        }
                    }
                    .ipt {
                        flex: 1;
                        height: 100%;
                        border: none;
                        outline: none;
                        font-size: 18px;
                        color: #666666;
                        padding: 0 10px;
                    }
                    .search {
                        width: 63px;
                        height: 42px;

                        /* 绿2 */
                        background: #34CD8B;
                        color: #FFFFFF;
                        font-size: 18px;
                        border: none;
                    }
                }
            }
            .hot-wrapper {
                /* 热门搜索：清洁能源 双碳政策 碳汇交易 */
                text-align: center;
                height: 26px;
                font-size: 18px;
                font-weight: normal;
                letter-spacing: 0em;
                /* White */
                color: #FFFFFF;
                padding-top: 30px;
                a {
                    font-size: 18px;
                    color: #FFFFFF;
                    margin-right: 10px;
                }
            }
        }
        .home-banner {
            height: 450px;
            background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/home/bg2.png");
            background-size: 100% 100%;
            .banner-wrapper {
                width: 70%;
                margin: 0 auto;
                padding-top: 120px;
                .title {
                    height: 93px;
                    font-family: SourceHanSansCN-Bold;
                    font-size: 64px;
                    font-weight: bold;
                    letter-spacing: 0em;

                    /* White */
                    color: #FFFFFF;
                }
                .subtitle {
                    font-size: 48px;
                    font-weight: 500;
                    letter-spacing: 0em;

                    /* White */
                    color: #FFFFFF;
                    padding: 20px 0;
                }
                .more {
                    display: inline-block;
                    line-height: 46px;
                    height: 46px;
                    padding: 0 10px;
                    border-radius: 4px;
                    /* 绿2 */
                    background: #34CD8B;
                    color: #FFFFFF;
                    font-size: 18px;
                }
            }
        }

    }

</style>
