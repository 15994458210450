<template>
    <div class="latest-policy">
        <div class="undp-content-wrapper">
            <div class="title-wrapper">
                <span class="title">最新政策</span>
<!--                <div class="desc">-->
<!--                    <a href="#">国家级</a>-->
<!--                    <a href="#">省市级</a>-->
<!--                    <a href="#">城市级</a>-->
<!--                    <a href="#">企业级</a>-->
<!--                </div>-->
            </div>
            <div class="content-wrapper">
                <div class="policy-item" v-for="(item,i) in list" :key="i" @click="toDetail(item.id)">
                    <div class="top">
                        <p class="policy-name">{{ item.policyName }}</p>
                    </div>
                    <div class="middle">
                        {{ item.releaseTime | formatDate2 }}
                    </div>
                    <div class="bottom">
                        {{ item.papers | glHtml }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getPolicyList} from "@/api/home";

    export default {
        name: "LatestPolicy",
        data() {
            return {
                list: [],
            }
        },
        mounted() {
            this.loadData()
        },
        filters: {
            formatDate2(str) {
                const date = new Date(str);
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                if(month<10){
                    month='0'+month;
                }
                var day = date.getDate();
                if(day<10){
                    day='0'+day;
                }

                return month + "/" + day
            },
            glHtml(str) {
                var reg = /(<([^>]+)>)/ig
                return str.replace(reg,'')
            }
        },
        methods: {
            toDetail(v) {
                this.$router.push('/policyDetail/'+v)
            },
            toAll() {
                this.$router.push('/tecnology')
            },
            loadData() {
                getPolicyList({pageNo: 1, pageSize: 4}).then(res => {
                    if(res.success) {
                        this.list = res.result.records
                    }
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .latest-policy {
        padding-top: 80px;
        padding-bottom: 50px;
        height: 680px;
        background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/home/latest-policy.png");
        background-size: 100% 100%;
        .title-wrapper {
            display: flex;
            align-items: center;
            .title {
                width: 240px;
                height: 50px;
                font-size: 40px;
                font-weight: bold;
                line-height: 50px;
                letter-spacing: 0em;

                /* 黑色标题 */
                color: #333333;
            }
            .desc {
                flex: 1;
                text-align: right;
                a {
                    display: inline-block;
                    height: 32px;
                    font-size: 16px;
                    line-height: 32px;
                    font-weight: normal;
                    letter-spacing: 0em;
                    padding: 0 20px;
                    /* 黑色标题 */
                    color: #333333;
                    margin-right: 20px;
                    &:hover {
                        border-radius: 4px;
                        /* 绿2 */
                        background: #34CD8B;
                        /* White */
                        color: #FFFFFF;

                    }
                }
                /*a:nth-of-type(2) {*/
                /*    display: inline-block;*/
                /*    width: 96px;*/
                /*    height: 32px;*/
                /*    line-height: 32px;*/
                /*    border-radius: 4px;*/
                /*    !* 绿2 *!*/
                /*    background: #34CD8B;*/
                /*    font-size: 16px;*/
                /*    font-weight: normal;*/
                /*    letter-spacing: 0em;*/

                /*    !* White *!*/
                /*    color: #FFFFFF;*/
                /*    text-align: center;*/
                /*}*/
            }
        }
        .content-wrapper {
            padding-top: 80px;
            display: flex;
            .policy-item {
                flex: 1;
                margin-right: 20px;
                background-color: #FFFFFF;

                box-sizing: border-box;
                padding-bottom: 50px;
                cursor: pointer;
                &:nth-of-type(4n) {
                    margin-right: 0;
                }
                .top {
                    width: 100%;
                    /*height: 150px;*/
                    /*background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/home/policy_item.png");*/
                    background-size: cover;
                    position: relative;
                    padding: 20px;
                    border-bottom: 1px solid #D8D8D8;
                    .policy-name {
                        height: 92px;
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 30px;
                        letter-spacing: 0em;
                        /* White */
                        /*color: #FFFFFF;*/
                        color: #333333;
                        box-sizing: border-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        word-break: break-all;
                    }
                }
                .middle {
                    padding: 0 10px;
                    box-sizing: border-box;
                    height: 66px;
                    font-size: 48px;
                    font-weight: 500;
                    letter-spacing: 0em;
                    /* 灰色 简介类 */
                    color: #999999;
                    margin-bottom: 15px;
                }
                .bottom {
                    font-size: 16px;
                    font-weight: normal;
                    line-height: 26px;
                    letter-spacing: 0em;
                    /* 灰色 简介类 */
                    color: #999999;
                    padding: 0 10px 50px;
                    box-sizing: border-box;

                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 5;
                    word-break: break-all;
                }
            }
        }
    }
</style>