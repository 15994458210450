
export function formatTime(date,num) {
	var time = date==null?"": Date.parse(date.replace(/-/g, '/'));
	var date = new Date(time);
	var year = date.getFullYear();
	var month = date.getMonth() + 1;
	if(month<10){
	  month='0'+month;
	}
	var day = date.getDate();
	if(day<10){
	  day='0'+day;
	}
	var hour = date.getHours();
	if(hour<10){
		  hour='0'+hour;
	}
	var minute = date.getMinutes();
	if(minute<10){
	  minute='0'+minute;
	}
	if(num == 3) {
		return year + "/" + month + "/" + day
	} else {
		return year + "/" + month + "/" + day +'  '+ hour+':'+minute;
	}
}
export function openNotificationWithIcon(type,msg) {
	this.$notification[type]({
		message: '提示',
		description: msg
	})
}

