<template>
    <div class="tecnology">
        <undp-header2></undp-header2>
        <div class="banner">
            <div class="undp-content-wrapper">
                <div class="title-wrapper">
                    <p class="title">专题活动</p>
                    <p class="sub-title">PEOJECT ACTIVITY</p>
                </div>
            </div>
        </div>
        <div class="search-wrapper">
            <div class="undp-content-wrapper">
                <div class="item">
                    <p class="title">行业领域：</p>
                    <div>
                        <a
                                href="javascript:void(0)"
                                :class="{'active':item.value == queryModel.departField }"
                                v-for="(item,i) in dtField"
                                :key="i"
                                @click="handleField(item.value)"
                        >{{ item.title }}</a>
                    </div>
                </div>
                <div class="item">
                    <p class="title">活动状态：</p>
                    <div>
                        <a href="javascript:void(0)"
                           :class="{'active':item.value == queryModel.activityState }"
                           v-for="(item,i) in dtActivityState"
                           :key="i"
                           @click="handleActivityState(item.value)">{{ item.title }}</a>
                    </div>

                </div>
                <div class="item">
                    <p class="title">活动形式：</p>
                    <div>
                        <a href="javascript:void(0)"
                           :class="{'active':item.value == queryModel.activityType }"
                           v-for="(item,i) in dtActivityType" :key="i"
                           @click="handleActivityType(item.value)">{{ item.title }}</a>
                    </div>
                </div>
<!--                <div class="item">-->
<!--                    <p class="title">活动时间：</p>-->
<!--                    <div>-->
<!--                        <a href="javascript:void(0)"-->
<!--                           :class="{'active':item.value == queryModel.dataFormat }"-->
<!--                           v-for="(item,i) in dtFormat" :key="i"-->
<!--                           @click="handleFormat(item.value)">{{ item.title }}</a>-->
<!--                    </div>-->
<!--                </div>-->
                <hr style="border: 1px solid rgba(0,0,0,0.08)">
                <div class="btn-wrapper">
                    <input type="button" value="查询" @click="handleQuery">
                </div>
            </div>
        </div>
        <div class="latest-tecnology">
            <div class="undp-content-wrapper">
                <div class="title-wrapper">
                    <div class="title">
                        <p class="default-title">专题活动</p>
                        <p class="sub-title">PEOJECT ACTIVITY</p>
                    </div>
                </div>
                <div class="activity-list">
                    <div class="item" v-for="(item,i) in list" :key="i" @click="toDetail(item.id)">
                        <div class="cover" :style="{'background-image': item.titleImg?'url('+item.titleImg+')':'url(https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/undp-default.png?x-oss-process=image/resize,m_fill,h_206,w_235)','background-position':'center'}"></div>
                        <div class="content">
                            <p class="time">{{ item.startTime | formatDate3 }}</p>
                            <p class="title">{{ item.title }}</p>
                            <div class="map-wrapper">
                                <img src="https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/activity/location.png" alt="">
                                <span>{{ $Area.getText(item.address)}}</span>
                            </div>
                            <div class="map-wrapper">
                                <img src="https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/activity/resource.png" alt="">
                                <span>{{ item.departField_dictText }}</span>
                            </div>
                            <div class="ljbm">
<!--                                <a href="#">立即报名</a>-->
                                <div class="seller">
                                    <img src="https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/activity/seller.png" alt="">
                                    <span>{{activityStateText(item.activityState)}}</span>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
<!--        <div class="pager" v-if="result.pages>1">-->
<!--            <div class="undp-content-wrapper">-->
<!--                <a href="#" @click="handlePage(item)" :class="{active: result.current == item}" v-for="(item,i) in result.pages">{{item}}</a>-->
<!--            </div>-->
<!--        </div>-->
        <undp-footer></undp-footer>
        <div v-if="loading" style="position: fixed;left: 0;top: 0;width:100%;height:100%;background: rgba(0,0,0,0.3);">
            <a-spin :spinning="loading" style="position: absolute;left: 50%;top: 50%;" tip="Loading..."></a-spin>
        </div>
    </div>
</template>

<script>
    import UndpHeader2 from "@/views/common/UndpHeader2"
    import UndpFooter from "@/views/common/UndpFooter"
    import {getLatestActivityList} from "@/api/activity"
    import { mapGetters } from 'vuex'
    import { formatTime } from '@/common/util/undpUtil'
    export default {
        name: "Activity",
        components: {
            UndpHeader2, UndpFooter
        },
        data() {
            return {
                result: {},
                list: [],
                queryModel: {
                    pageNo: 1,
                    pageSize: 10,
                    activityState: '',
                    activityType: '',
                    departField: '',
                    startTime: '',
                    endTime: ''
                },
                loading: true
            }
        },
        computed: {
            ...mapGetters(['dtField','dtActivityState','dtActivityType','dtFormat']),
            activityStateText() {
                return function (str) {
                    return this.dtActivityState.find(v => v.value == str).text
                }
            }
        },
        mounted() {
            this.loadData()
            this.scroll()
        },
        filters: {
            formatDay(str) {
                const date = new Date(str);
                return date.getDate()
            },
            formatDate2(str) {
                const date = new Date(str);
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                if(month<10){
                    month='0'+month;
                }
                return year + "/" + month
            },
            formatDate3(str) {

                return formatTime(str,5)
            },
        },
        methods: {
            toDetail(v) {
                this.$router.push('/activityDetail/'+v)
            },
            loadData() {
                this.loading = true
                getLatestActivityList(this.queryModel).then(res => {
                    if(res.success) {
                        // this.list = res.result.records
                        this.result = res.result
                        this.list = [...this.list, ...res.result.records]
                        this.loading = false
                    }
                })
            },
            handlePage(pageNo) {
                this.queryModel.pageNo = pageNo
                this.loadData()
            },
            handleQuery() {
                this.queryModel.pageNo = 1
                this.list = []
                this.loadData()
            },
            scroll() {
                // let isLoading = true;//是否有数据可以加载
                window.onscroll = async () => {
                    // 距离底部200px时加载一次
                    // let bottomOfWindow = document.documentElement.offsetHeight - document.documentElement.scrollTop - window.innerHeight <= 200;
                    // if (bottomOfWindow) {
                    //     if (this.queryModel.pageNo >= this.result.pages) return
                    //     this.queryModel.pageNo+=1
                    //     this.loadData()
                    // }
                    let bottomOfWindow = document.documentElement.scrollHeight-document.documentElement.scrollTop-document.documentElement.clientHeight
                    if (bottomOfWindow < 310) {
                        if (this.queryModel.pageNo >= this.result.pages) return
                        this.queryModel.pageNo+=1
                        this.loadData()
                    }
                }
            },
            handleField(v) {
                this.queryModel.departField = v
            },
            handleActivityState(v) {
                this.queryModel.activityState = v
            },
            handleActivityType(v) {
                this.queryModel.activityType = v
            },
        }

    }
</script>

<style scoped lang="less">
    .tecnology {
        .banner {
            height: 450px;
            background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/activity/bg.png");
            background-size: 100% 100%;
            .undp-content-wrapper {
                position: relative;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                .title-wrapper {
                    .title {
                        height: 93px;
                        font-family: SourceHanSansCN-Bold;
                        font-size: 64px;
                        font-weight: bold;
                        letter-spacing: 0em;
                        margin-bottom: 0;
                        /* White */
                        color: #FFFFFF;
                    }
                    .sub-title {
                        height: 43px;
                        font-size: 30px;
                        font-weight: normal;
                        text-transform: uppercase;
                        letter-spacing: 0em;

                        /* White */
                        color: #FFFFFF;
                    }
                }
            }
        }
        .search-wrapper {
            padding-top: 60px;
            .item {
                /*display: flex;*/
                /*align-items: center;*/
                margin-bottom: 20px;
                .title {
                    height: 23px;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0em;
                    /* 标题小字 */
                    color: #666666;
                }
                .ipt-wrapper {
                    padding-top: 10px;
                    .ipt {
                        width: 591px;
                        height: 40px;
                        border-radius: 4px;
                        box-sizing: border-box;
                        outline: none;
                        /* Border/Secondary */
                        border: 1px solid #AAAAAA;
                        padding: 0 20px;
                    }
                }
                a {
                    display: inline-block;
                    height: 30px;
                    line-height: 30px;
                    font-family: SourceHanSansCN-Regular;
                    font-size: 16px;
                    font-weight: normal;
                    letter-spacing: 0em;
                    margin-right: 20px;
                    /* Text/Secondary */
                    color: #767676;
                    padding: 0 10px;
                    text-align: center;
                    &.active {
                        /* 绿2 */
                        background: #34CD8B;
                        color: #FFFFFF;
                    }
                }
            }
            .btn-wrapper {
                text-align: right;
                padding-top: 15px;
                input {
                    cursor: pointer;
                    width: 80px;
                    height: 32px;
                    /* 绿2 */
                    background: #34CD8B;
                    /* 筛选 */

                    font-family: SourceHanSansCN-Regular;
                    font-size: 16px;
                    font-weight: normal;
                    letter-spacing: 0em;
                    line-height: 32px;
                    /* White */
                    color: #FFFFFF;
                    border: none;
                    outline: none;
                }
            }
        }
        .latest-tecnology {
            padding-top: 80px;
            .title-wrapper {
                display: flex;
                align-items: center;
                .title {
                    .default-title {
                        height: 58px;
                        font-size: 40px;
                        font-weight: bold;
                        letter-spacing: 0em;
                        color: #3D3D3D;
                        margin-bottom: 0;
                    }
                    .sub-title {
                        height: 23px;
                        font-size: 16px;
                        font-weight: bold;
                        text-transform: uppercase;
                        letter-spacing: 0em;

                        /* 灰色 简介类 */
                        color: #999999;
                    }
                }
                .desc {
                    flex: 1;
                    text-align: right;
                    a {
                        display: inline-block;
                        height: 32px;
                        font-size: 16px;
                        line-height: 32px;
                        font-weight: normal;
                        letter-spacing: 0em;
                        padding: 0 20px;
                        /* 黑色标题 */
                        color: #333333;
                        margin-right: 20px;
                        &:hover {
                            border-radius: 4px;
                            /* 绿2 */
                            background: #34CD8B;
                            /* White */
                            color: #FFFFFF;

                        }
                    }
                }
            }
            .activity-list {
                display: flex;
                flex-wrap: wrap;
                .item {
                    margin: 0 40px 40px 0;
                    width: calc((100% - 40px) / 2);
                    box-sizing: border-box;
                    border-bottom: 1px solid rgba(0,0,0,0.08);
                    cursor: pointer;
                    padding: 40px 20px;
                    display: flex;
                    &:nth-of-type(2n) {
                        margin-right: 0;
                    }
                    &:hover {
                        background: #F5F5F5;
                    }
                    &.active {
                        background: #F5F5F5;
                    }
                    .cover {
                        width: 235px;
                        height: 206px;
                        background-size: cover;
                    }
                    .content {
                        flex: 1;
                        padding: 0 20px;
                        box-sizing: border-box;
                        .time {
                            height: 30px;
                            font-family: AlibabaPuHuiTiB;
                            font-size: 20px;
                            font-weight: bold;
                            line-height: 30px;
                            letter-spacing: 0em;
                            /* 绿 */
                            color: #28BF72;
                            margin-bottom: 2px;
                        }
                        .title {
                            height: 60px;
                            font-family: SourceHanSansCN-Regular;
                            font-size: 20px;
                            font-weight: normal;
                            line-height: 30px;
                            letter-spacing: 0em;
                            /* 黑色标题 */
                            color: #333333;
                        }
                        .map-wrapper {
                            height: 20px;
                            margin-bottom: 4px;
                            display: flex;
                            align-items: center;
                            span {
                                display: inline-block;
                                height: 20px;
                                line-height: 20px;
                                font-family: SourceHanSansCN-Regular;
                                font-size: 16px;
                                font-weight: normal;
                                letter-spacing: 0em;
                                /* 标题小字 */
                                color: #666666;
                                margin-left: 6px;
                            }
                        }
                        .ljbm {
                            height: 32px;
                            display: flex;
                            align-items: center;
                            padding-top: 20px;
                            a {
                                display: inline-block;
                                width: 80px;
                                height: 32px;
                                line-height: 32px;
                                border-radius: 4px;
                                /* 绿2 */
                                background: #34CD8B;
                                font-family: SourceHanSansCN-Regular;
                                font-size: 16px;
                                font-weight: normal;
                                letter-spacing: 0em;
                                text-align: center;
                                /* White */
                                color: #FFFFFF;
                            }
                            .seller {
                                flex: 1;
                                display: flex;
                                align-items: center;
                                /*padding-left: 20px;*/
                                box-sizing: border-box;
                                img {
                                    margin-right: 5px;
                                }
                                span {
                                    display: inline-block;
                                    font-family: SourceHanSansCN-Regular;
                                    font-size: 16px;
                                    font-weight: normal;
                                    letter-spacing: 0em;

                                    /* 标题小字 */
                                    color: #666666;
                                }
                            }
                        }
                        .desc {
                            padding-top: 10px;
                            height: 72px;
                            font-size: 16px;
                            font-weight: normal;
                            line-height: 26px;
                            letter-spacing: 0em;
                            /* 灰色 简介类 */
                            color: #999999;
                        }

                    }

                }
            }
        }
        .pager {
            padding: 60px 0 100px 0;
            a {
                display: inline-block;
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                font-size: 20px;
                font-weight: normal;
                letter-spacing: 0em;
                /* 灰色 简介类 */
                color: #999999;
                margin-right: 10px;
                &.active {
                    background-color: #34CD8B;
                    color: #FFFFFF;
                }
            }
        }
    }

</style>
