<template>
    <iframe class="myframe" :src="path" frameborder="0"></iframe>
</template>

<script>
    export default {
        name: "preview",
        data() {
          return {
              path: ''
          }
        },
        created() {
            this.path = this.$ls.get('path')
        }
    }
</script>

<style scoped lang="less">
    .myframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

</style>