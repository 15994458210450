<template>
    <div class="latest-tec">
        <div class="undp-content-wrapper">
            <div class="title-wrapper">
                <span class="title">最新创新技术</span>
                <div class="desc">
                    <a href="#" @click="toAll">全部</a>
<!--                    <a href="">最新</a>-->
                </div>
            </div>
            <div class="content-wrapper">
                <div class="left" @click="toDetail(list[0].id)">
                    <img src="https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/latest.png" width="100%" style="display: block"/>

                    <div class="item" v-if="list[0]">
                        <div class="time-wrapper">
                            <p class="day wht">{{ list[0].createTime | formatDay }}</p>
                            <p class="month wht">{{ list[0].createTime | formatDate2 }}</p>
                        </div>
                        <span style="flex: 1">{{ list[0].innovateName }}</span>
                    </div>
                </div>
                <div class="right">
                    <div :class="{ sel: i == 1,item: true }" v-for="(item,i) in rightList" :key="i" @click="toDetail(item.id)">
                        <div class="time-wrapper">
                            <p :class="{ wht: i == 1,day: true }">{{ item.createTime | formatDay }}</p>
                            <p  :class="{ wht: i == 1,month: true }">{{ item.createTime | formatDate2 }}</p>
                        </div>
                        <p class="title">{{ item.innovateName }}</p>
                        <p class="desc">{{ item.departField_dictText }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getInnovateList } from '@/api/home'
    export default {
        name: "LatestTec",
        data() {
          return {
              list: [],
              rightList: []
          }
        },
        mounted() {
            this.loadData()
        },
        filters: {
          formatDay(str) {
              const date = new Date(str);
              return date.getDate()
          },
            formatDate2(str) {
                const date = new Date(str);
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                if(month<10){
                    month='0'+month;
                }
                return year + "/" + month
            }
        },
        methods: {
            toAll() {
                this.$router.push('/tecnology')
            },
            toDetail(v) {
                this.$router.push('/tecnologyDetail/'+v)
            },
            loadData() {
                getInnovateList({pageNo: 1, pageSize: 4}).then(res => {
                    if(res.success) {
                        this.list = res.result.records
                        this.rightList = this.list.slice(1)
                    }
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .latest-tec {
        padding-top: 80px;
        height: 600px;
        background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/home/latest.png");
        background-size: 100% 100%;
        .title-wrapper {
            display: flex;
            align-items: center;
            .title {
                width: 240px;
                height: 50px;
                font-size: 40px;
                font-weight: bold;
                line-height: 50px;
                letter-spacing: 0em;

                /* 黑色标题 */
                color: #333333;
            }
            .desc {
                flex: 1;
                text-align: right;
                a {
                    display: inline-block;
                    height: 32px;
                    font-size: 16px;
                    line-height: 32px;
                    font-weight: normal;
                    letter-spacing: 0em;
                    padding: 0 20px;
                    /* 黑色标题 */
                    color: #333333;
                    margin-right: 20px;
                    &:hover {
                        border-radius: 4px;
                        /* 绿2 */
                        background: #34CD8B;
                        /* White */
                        color: #FFFFFF;

                    }
                    a:last-of-type {
                        margin-right: 0;
                    }
                }
                /*a:nth-of-type(2) {*/
                /*    display: inline-block;*/
                /*    width: 96px;*/
                /*    height: 32px;*/
                /*    line-height: 32px;*/
                /*    border-radius: 4px;*/
                /*    !* 绿2 *!*/
                /*    background: #34CD8B;*/
                /*    font-size: 16px;*/
                /*    font-weight: normal;*/
                /*    letter-spacing: 0em;*/

                /*    !* White *!*/
                /*    color: #FFFFFF;*/
                /*    text-align: center;*/
                /*}*/
            }
        }
        .content-wrapper {
            padding-top: 80px;
            display: flex;
            .left {
                flex: 1;
                margin-right: 20px;
                cursor: pointer;
                .item {
                    display: flex;
                    align-items: center;
                    background-color: #FFFFFF;
                    .time-wrapper {
                        width: 80px;
                        background-color: #34CD8B;
                        color: #FFFFFF;
                        text-align: center;
                        margin-right: 10px;
                        .day {
                            height: 66px;
                            font-size: 48px;
                            font-weight: bold;
                            letter-spacing: 0em;
                            margin-bottom: 0;
                        }
                        .month {
                            height: 19px;
                            font-size: 14px;
                            font-weight: normal;
                            letter-spacing: 0em;
                        }
                    }
                }
            }
            .right {
                flex: 2;
                box-sizing: border-box;
                display: flex;
                .item {
                    background-color: #FFFFFF;
                    flex: 1;
                    box-sizing: border-box;
                    padding: 18px 20px;
                    box-sizing: border-box;
                    cursor: pointer;
                    .time-wrapper {
                        .day {
                            height: 66px;
                            font-size: 48px;
                            font-weight: bold;
                            letter-spacing: 0em;
                            /* 绿2 */
                            color: #34CD8B;
                            margin-bottom: 0;
                        }
                        .month {
                            height: 19px;
                            font-size: 14px;
                            font-weight: normal;
                            letter-spacing: 0em;

                            /* 绿2 */
                            color: #34CD8B;
                        }
                    }
                    .title {
                        /* 创新技术名称创新技术名称创新技术名称创新技术名称 */
                        padding: 30px 0;
                        font-size: 20px;
                        font-weight: normal;
                        line-height: 30px;
                        letter-spacing: 0em;

                        /* 黑色标题 */
                        color: #333333;
                    }
                    .desc {
                        font-size: 16px;
                        font-weight: normal;
                        line-height: 26px;
                        letter-spacing: 0em;

                        /* 灰色 简介类 */
                        color: #999999;
                    }
                    &.sel {
                        background-color: #34CD8B;
                        p {
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }
    }
</style>