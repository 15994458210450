import Vue from 'vue'
// 封装axios
import axios from 'axios'
// 配置默认的访问路径
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
console.log('baseURL:',axios.defaults.baseURL)
// 请求拦截
axios.interceptors.request.use(config => {
    config.headers[ 'agen' ] = 'front'
    const token = Vue.ls.get('Access-Token')
    if (token) {
        config.headers[ 'X-Access-Token' ] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
    }
  // 配置请求头中添加token
  //   config.headers[ 'X-Access-Token' ] = this.$ls.get('Access-Token')
  return config
})
axios.interceptors.response.use(
    response => {
      if (response.status == 200) {
        return Promise.resolve(response.data);
      } else {
        return Promise.reject(response.data);
      }
    },
    error => {
      alert(JSON.stringify(error), '请求异常', {
        confirmButtonText: '确定',
        callback: (action) => {
          console.log(action)
        }
      });
    }
);

// 导出
export default axios
