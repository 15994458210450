<template>
    <div>NOT FOUND</div>
</template>

<script>
    export default {
        name: "NotFund"
    }
</script>

<style scoped>

</style>