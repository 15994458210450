<template>
    <div class="tecnology-detail">
        <div class="detail-header">
            <undp-header></undp-header>
        </div>
        <div class="detail-content">
            <div class="undp-content-wrapper">
                <div class="activity-header">
                    <div class="cover" :style="{'background-image': dt.titleImg?'url('+dt.titleImg+')':'url(https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/undp-default.png?x-oss-process=image/resize,m_fill,h_500,w_400)','background-position':'center'}">
                    </div>
                    <div class="content">
                        <p class="title">{{ dt.title }}</p>
                        <div class="desc-wrapper">
                            <p>时间：{{ dt.startTime | formatDate3 }} - {{ dt.endTime | formatDate3 }}</p>
                            <p>地点：{{ $Area.getText(dt.address)}}</p>
<!--                            <p>发布人：</p>-->
                        </div>
                        <div class="ticket-wrapper">
                            <div class="module" style="width: 100px;margin-bottom:0">
                                <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                                <span>活动票种</span>
                            </div>
                            <div class="ticket-list">
                                <div
                                        class="ticket-item"
                                        v-for="(item,i) in ticketList"
                                        :key="i"
                                        :class="{ active: item.id == tv.id }"
                                >
                                    {{ item.synopsis }} {{ item.price?item.price+'元':'免费' }}
                                </div>
                            </div>
                        </div>
                        <div class="ticket-wrapper" v-if="tv.id">
                            <div class="module" style="width: 100px">
                                <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                                <span>购票期限</span>
                            </div>
                            <div class="ticket-list">
                                <div class="ticket-item">
                                    {{ tv.startTime | formatDate3 }} - {{ tv.endTime | formatDate3 }}
                                </div>
                            </div>
                        </div>
                        <div class="ticket-wrapper" v-if="tv.id">
                            <div class="module" style="width: 100px">
                                <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                                <span>购票数量</span>
                            </div>
                            <div class="ticket-list">
                                <input class="minus" type="button" value="-" @click="handleMinus">
                                <span class="count">{{ num }}张</span>
                                <input class="minus plus" type="button" value="+" @click="handlePlus">
                                <span class="balance">（剩余{{ tv.surplus }}/{{ tv.quantity }}张）</span>
                            </div>
                        </div>
                        <div class="ticket-wrapper" v-if="tv.id">
                            <div class="module" style="width: 100px">
                                <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                                <span>合计</span>
                            </div>
                            <div class="ticket-list">
                                <div class="total-wrapper">
                                    <span class="dw">¥</span>
                                    <span class="price">{{(tv.price * num).toFixed(2) }}</span>
                                </div>
                            </div>
                        </div>
<!--                        <div class="ticket-wrapper">-->
<!--                            <div class="module" style="width: 100px"></div>-->
<!--                            <div class="ticket-list">-->
<!--                                <input class="btn-buy" type="button" value="请扫码购票">-->
<!--                            </div>-->
<!--                        </div>-->
                        <div class="code-wrapper" v-if="url">
                            <img :src="url" alt="" width="200" height="200">
                        </div>


                    </div>
                </div>
                <div class="content">
                    <div class="module">
                        <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                        <span>简介</span>
                    </div>
                    <div style="position: relative;" v-html="dt.synopsis"></div>
                </div>
                <div class="video" v-if="videoList.length>0">
                    <div class="module">
                        <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                            <span>活动视频</span>
                    </div>
                    <div class="video-content">
                        <video v-for="(item,i) in videoList" :key="i" :src="item.videoUrl" controls style="width: 100%;margin-bottom: 10px" @play="handlePlay(i)"></video>
                    </div>

                </div>
                <div class="expert" v-if="ticketUsers.length>0">
                    <div class="module">
                        <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                        <span>参与报名</span>
                    </div>
                    <div class="expert-content">
                        <div class="bm-list">
                            <div class="bm-item" v-for="(item,i) in ticketUsers">
                                <img :src="item.avatar" alt="">
                                <div class="name">{{ item.dataName }}</div>
                            </div>
                            <div class="bm-item" v-if="total>0">
                                <div class="total">{{ '+'+total }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <undp-footer></undp-footer>
        </div>



    </div>
</template>

<script>
    import UndpHeader from "@/views/common/UndpHeader"
    import UndpFooter from "@/views/common/UndpFooter"
    import {getDetail, getTicketUsers, getTicketList, getVideos } from "@/api/activity"
    import { formatTime } from '@/common/util/undpUtil'
    export default {
        name: "ActivityDetail",
        components: {
            UndpHeader, UndpFooter
        },
        data() {
          return {
              dt: {},
              videos: [],
              certificates: [],
              documents: [],
              ticketUsers: [],
              total: 0,
              ticketList: [],
              videoList: [],
              videoElements: [],
              id: '',
              tv: {
                  id: ''
              },
              num: 1,
              url: ''
          }
        },
        filters: {
            formatDate3(str) {
                return formatTime(str,5)
            },
        },
        mounted() {
            this.loadData()
            this.loadTickets()
            this.loadVideos()
        },
        methods: {
            handlePay() {
                // this.$notification['warning']({
                //     message: '提示',
                //     description: '请转到小程序购票'
                // })

            },
            handleMinus() {
                this.num--
                if(this.num < 1) {
                    this.num = 1
                }
            },
            handlePlus() {
                this.num++
                if(this.num > this.tv.surplus) {
                    this.num--
                }
            },
            handleChoose(item) {
                this.num = 1
                this.tv = item
            },
            loadTicketUsers(activityId) {
                getTicketUsers(activityId).then(res => {
                    const result = res.result
                    this.total = result.total - 7
                    this.ticketUsers = result.records
                })
            },
            loadData() {
                this.id = this.$route.params.id
                this.url = `${process.env.VUE_APP_API_BASE_URL}/undp/undpActivity/createActivityQRcode?activityId=${this.id}&envFlag=${process.env.VUE_APP_ENVFLAG}`
                getDetail(this.id).then(res => {
                    console.log('res:', res)
                    this.dt = res.result
                    this.loadTicketUsers(this.id)
                })
            },
            handleToPreview(path) {
                this.$ls.set('path', path)
                window.open('/preview','_blank')
            },
            loadTickets() {
                getTicketList(this.id).then(res => {
                    // console.log('res:', res)
                    this.ticketList = res.result
                    this.tv = {}
                })
            },
            loadVideos() {
                getVideos(this.id).then(res => {
                    if(res.success) {
                        this.videoList = res.result.records
                        this.videoElements = document.getElementsByTagName('video')
                    }
                })
            },
            handlePlay(i) {
                const token = this.$ls.get('Access-Token')
                if(!token) {
                    this.$notification['warning']({
                        message: '提示',
                        description: '请先登录'
                    })
                    this.videoElements[i].pause()
                }
            },

        }
    }
</script>

<style scoped lang="less">
    .tecnology-detail {
        .detail-header {
            height: 350px;
            background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/tecnology/dtbg.png");
            background-size: 100% auto;
        }
        .detail-content {
            position: absolute;
            left: 0;
            top: 200px;
            width: 100%;
            box-sizing: border-box;
            .undp-content-wrapper {
                background-color: #FFFFFF;
                box-sizing: border-box;
                padding: 80px 40px 40px;
                .activity-header {
                    display: flex;
                    .cover {
                        width: 400px;
                        height: 500px;
                        background-size: 100% 100%;
                    }
                    .content {
                        position: relative;
                        flex: 1;
                        padding-left: 50px;
                        box-sizing: border-box;
                        border-bottom: none;
                        .title {
                            max-height: 84px;
                            font-family: SourceHanSansCN-Medium;
                            font-size: 32px;
                            font-weight: 500;
                            line-height: 42px;
                            letter-spacing: 0em;

                            /* 黑色标题 */
                            color: #333333;
                        }
                        .desc-wrapper {
                            margin-bottom: 50px;
                            p {
                                margin-bottom: 15px;
                                height: 19.42px;
                                font-family: SourceHanSansCN-Regular;
                                font-size: 20px;
                                font-weight: normal;
                                line-height: 19.42px;
                                letter-spacing: 0em;

                                /* 标题小字 */
                                color: #666666;
                            }
                        }
                        .ticket-wrapper {
                            display: flex;
                            margin-bottom: 30px;
                            .ticket-list {
                                flex: 1;
                                margin-left: 20px;
                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;
                                .ticket-item {
                                    height: 30px;
                                    line-height: 30px;
                                    text-align: center;
                                    box-sizing: border-box;
                                    /* 灰2 */
                                    border: 0.5px solid #CCCCCC;
                                    font-size: 16px;
                                    font-weight: normal;
                                    letter-spacing: 0em;
                                    /* 标题小字 */
                                    color: #666666;
                                    padding: 0 10px;
                                    margin: 0 12px 12px 0;
                                    border-radius: 4px;
                                    cursor: pointer;
                                    &.active {
                                        background: rgba(52, 205, 139, 0.1);
                                        /* 绿2 */
                                        border: 0.5px solid #34CD8B;
                                        color: #34CD8B;
                                    }
                                }
                                .minus {
                                    width: 22px;
                                    height: 26px;
                                    border-radius: 4px;
                                    /* 灰2 */
                                    background: #CCCCCC;
                                    color: #FFFFFF;
                                }
                                .plus {
                                    /* 绿2 */
                                    background: #34CD8B;
                                }
                                .count {
                                    margin: 0 20px;
                                }
                                .balance {
                                    height: 19.42px;
                                    font-family: SourceHanSansCN-Regular;
                                    font-size: 16px;
                                    font-weight: normal;
                                    line-height: 19.42px;
                                    letter-spacing: 0em;
                                    /* 灰色 简介类 */
                                    color: #999999;
                                }
                                .total-wrapper {
                                    .dw {
                                        width: 20px;
                                        height: 22px;
                                        font-family: AlibabaPuHuiTiM;
                                        font-size: 20px;
                                        font-weight: 500;
                                        line-height: 22px;
                                        letter-spacing: -0.08px;

                                        /* 绿2 */
                                        color: #34CD8B;
                                        margin-right: 10px;
                                    }
                                    .price {
                                        height: 22px;
                                        font-family: AlibabaPuHuiTiM;
                                        font-size: 40px;
                                        font-weight: 500;
                                        line-height: 22px;
                                        letter-spacing: -0.08px;

                                        /* 绿2 */
                                        color: #34CD8B;
                                    }
                                }
                                .btn-buy {
                                    height: 40px;
                                    line-height: 40px;
                                    border-radius: 4px;
                                    color: #FFFFFF;
                                    /* 绿2 */
                                    background: #34CD8B;
                                    border: none;
                                    cursor: pointer;
                                    padding: 0 10px;
                                }
                            }
                        }
                        .module {
                            margin-bottom: 0;
                        }
                        .code-wrapper {
                            position: absolute;
                            right: 0;
                            top: 100px;
                        }
                    }
                }
                .module {
                    display: flex;
                    align-items: center;
                    height: 30px;
                    margin-bottom: 40px;
                    span {
                        height: 30px;
                        font-family: SourceHanSansCN-Medium;
                        font-size: 20px;
                        font-weight: 500;
                        letter-spacing: 0em;
                        line-height: 30px;
                        /* 黑色标题 */
                        color: #333333;
                        margin-left: 8px;
                    }
                }
                .content {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                }
                .movies {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                }
                .zl {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                    .zl-list {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        .zl-item {
                            width: calc((100% - 60px) / 2);
                            height: 150px;
                            margin: 0 60px 20px 0;
                            background: #F5F5F5;
                            border: 1px solid rgba(0, 0, 0, 0.08);
                            display: flex;
                            align-items: center;
                            box-sizing: border-box;
                            padding: 0 30px;
                            cursor: pointer;
                            &:nth-of-type(2n) {
                                margin-right: 0;
                            }
                            .zl-cover {
                                margin-right: 30px;
                            }
                            .zl-content {
                                p:first-of-type {
                                    height: 65px;
                                    font-family: SourceHanSansCN-Regular;
                                    font-size: 20px;
                                    font-weight: normal;
                                    line-height: 30px;
                                    letter-spacing: 0em;

                                    /* 文字 */
                                    color: rgba(0, 0, 0, 0.8);
                                }
                                p:last-of-type {
                                    height: 23px;
                                    font-family: SourceHanSansCN-Regular;
                                    font-size: 16px;
                                    font-weight: normal;
                                    letter-spacing: 0em;

                                    /* 灰色 简介类 */
                                    color: #999999;
                                }
                            }
                        }
                    }
                }
                .honer {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                    .honer-content {
                        display: flex;
                        flex-wrap: wrap;
                        .cover {
                            width: calc((100% - 60px) / 4);
                            padding-bottom: 30%;
                            margin: 0 20px 20px 0;
                            background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/tecnology/zs.jpeg");
                            background-size: 100% 100%;
                            &:nth-of-type(4n) {
                                margin-right: 0;
                            }
                        }
                    }
                }
                .organize {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                    .org-wrapper {
                        display: flex;
                        align-items: center;
                        .cover {
                            width: 120px;
                            height: 120px;
                            margin-right: 50px;
                            background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/home/qh.jpeg");
                            background-size: 100% 100%;
                        }
                        .org-content {
                            flex: 1;
                        }
                    }

                }
                .video {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                }
                .expert {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                    .expert-content {
                        .bm-list {
                            display: flex;
                            flex-wrap: wrap;
                            .bm-item {
                                width: calc((100% - 70px) / 8);
                                text-align: center;
                                margin-right: 10px;
                                &:nth-of-type(5n) {
                                    margin-right: 0;
                                }
                                img {
                                    width: 80px;
                                    height: 80px;
                                    margin-bottom: 10px;
                                }
                                .name {
                                    height: 24px;
                                    font-family: SourceHanSansCN-Regular;
                                    font-size: 16px;
                                    font-weight: normal;
                                    line-height: 24px;
                                    letter-spacing: 0px;
                                    /* 文字40% */
                                    color: rgba(0, 0, 0, 0.4);
                                }
                                .total {
                                    width: 60px;
                                    height: 60px;
                                    background: rgba(0, 0, 0, 0.1);
                                    border-radius: 50%;
                                    font-family: SourceHanSansCN-Regular;
                                    font-size: 16px;
                                    font-weight: normal;
                                    line-height: 60px;
                                    letter-spacing: 0px;

                                    /* 纯白 */
                                    color: #FFFFFF;
                                }
                            }
                        }


                    }
                }
                .comment {
                    padding: 40px 0;
                    .comment-title {
                        padding-bottom: 40px;
                        p:first-of-type {
                            height: 29px;
                            font-family: SourceHanSansCN-Medium;
                            font-size: 20px;
                            font-weight: 500;
                            letter-spacing: 0em;

                            /* 黑色标题 */
                            color: #333333;
                        }
                        p:last-of-type {
                            height: 20px;
                            font-family: SourceHanSansCN-Regular;
                            font-size: 14px;
                            font-weight: normal;
                            text-transform: uppercase;
                            letter-spacing: 0em;

                            /* 标题小字 */
                            color: #666666;
                        }
                    }
                    .comment-ipt {
                        border-top: 1px solid #CCCCCC;
                        border-bottom: 1px solid #CCCCCC;
                        box-sizing: border-box;
                        padding: 40px 0;
                        textarea {
                            width: 100%;
                            height: 84px;
                            outline: none;
                            /* 灰 */
                            background: #F5F5F5;
                            border: 1px solid rgba(0, 0, 0, 0.08);
                            padding: 20px;
                            box-sizing: border-box;
                        }
                        .btn-comment {
                            width: 80px;
                            height: 32px;
                            line-height: 32px;
                            /* 绿2 */
                            background: #34CD8B;
                            font-family: SourceHanSansCN-Regular;
                            font-size: 16px;
                            font-weight: normal;
                            letter-spacing: 0em;
                            /* White */
                            color: #FFFFFF;
                            border: none;
                            margin-top: 20px;
                        }
                    }
                    .comment-list {
                        padding-top: 20px;
                        .comment-item {
                            height: 60px;
                            display: flex;
                            align-items: center;
                            margin-bottom: 20px;
                            .cover {
                                width: 50px;
                                height: 50px;
                                background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/tecnology/head.png");
                                background-size: 100% 100%;
                                margin-right: 25px;
                            }
                            .comment-content {
                                flex: 1;
                                .top {
                                    span {
                                        &:first-of-type {
                                            height: 30px;
                                            font-family: SourceHanSansCN-Regular;
                                            font-size: 16px;
                                            font-weight: normal;
                                            line-height: 30px;
                                            letter-spacing: 0em;
                                            /* 标题小字 */
                                            color: #666666;
                                            margin-right: 20px;
                                        }
                                        &:nth-of-type(2) {
                                            height: 30px;
                                            font-family: SourceHanSansCN-Regular;
                                            font-size: 14px;
                                            font-weight: normal;
                                            line-height: 30px;
                                            letter-spacing: 0em;

                                            /* 灰色 简介类 */
                                            color: #999999;
                                        }
                                    }
                                }
                                .bottom {
                                    height: 30px;
                                    font-family: SourceHanSansCN-Regular;
                                    font-size: 16px;
                                    font-weight: normal;
                                    line-height: 30px;
                                    letter-spacing: 0em;
                                    /* 标题小字 */
                                    color: #666666;
                                }
                            }
                        }
                    }
                }
            }

        }

    }
</style>
