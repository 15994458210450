<template>
    <div class="login-wx">
        <img class="logo" :src="logo3" height="60" width="60"/>
        <p class="title">
            UNDP节能技术分享平台系统
        </p>
        <div class="qrcode-wrapper">
            <img class="qrcode" :src="url" alt="">
            <div>
                <img src="https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/wx-login.png" alt="">
            </div>
        </div>
        <div>
            <a href="javascript:void(0)" class="zh-login" @click="back">账号登录</a>
        </div>
    </div>
</template>

<script>
    import { qrCodeLogin } from '@/api/login'
    import { v4 } from 'uuid';
    import Vue from "vue";
    import {ACCESS_TOKEN, UI_CACHE_DB_DICT_DATA, USER_INFO, USER_NAME} from "@/store/mutation-types";
    import logo3 from '@/assets/client/undp-logo3.png'

    export default {
        name: "LoginWx",
        data() {
          return {
              uuid: '',
              url: '',
              timer: '',
              logo3: logo3
          }
        },
        created() {
            let newUuid = v4()
            this.uuid = newUuid.replace(/[-]/g, '')
            this.url = `${process.env.VUE_APP_API_BASE_URL}/sys/createWxLoginQRcode?uuid=${this.uuid}&envFlag=${process.env.VUE_APP_ENVFLAG}`

            this.timer = setInterval(this.handleLogin,500)

        },
        methods: {
            back() {
                this.$emit('back')
            },
            async handleLogin() {
                const response = await qrCodeLogin(this.uuid)
                if(response.result) {
                    //
                    clearInterval(this.timer)

                    const result = response.result
                    const userInfo = result.userInfo
                    Vue.ls.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
                    Vue.ls.set(USER_NAME, userInfo.username, 7 * 24 * 60 * 60 * 1000)
                    Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000)
                    Vue.ls.set(UI_CACHE_DB_DICT_DATA, result.sysAllDictItems, 7 * 24 * 60 * 60 * 1000)
                    Vue.ls.set('realname',userInfo.realname)
                    this.$store.commit('SET_TOKEN', result.token)
                    this.$store.commit('SET_INFO', userInfo)
                    this.$store.commit('SET_NAME', { username: userInfo.username,realname: userInfo.realname, welcome: '' })
                    this.$store.commit('SET_AVATAR', userInfo.avatar)

                    this.$router.push('/home')
                }

            },
        }
    }
</script>

<style scoped lang="less">
    .login-wx {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .logo {
            margin: 20px 0;
        }
        .title {
            /* 登录UNDP节能技术分享平台系统 */
            height: 29px;
            line-height: 29px;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0em;
            /* 黑色标题 */
            color: #333333;
        }
        .qrcode-wrapper {
            padding: 30px;
            background: #FFFFFF;
            box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.16);
            text-align: center;
            .qrcode {
                width: 180px;
                height: 180px;
                border: 1px solid #CCCCCC;
                margin-bottom: 20px;
            }
        }
        .zh-login {
            margin-top: 20px;
            display: inline-block;
            width: 116px;
            height: 32px;
            line-height: 32px;
            border-radius: 4px;
            /* 绿2 */
            border: 1px solid #666666;
            font-family: SourceHanSansCN-Regular;
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 0em;
            text-align: center;
            /* 标题小字 */
            color: #666666;
        }
    }
</style>
