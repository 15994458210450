<template>
    <div class="login-wrapper">
        <div class="login-box">
            <div class="left"></div>
            <div class="right-wx" v-if="loginType == 3">
                <login-wx @back="handleBack"></login-wx>
            </div>
            <div class="right" v-else>
                <div class="sm-login">
                    扫码登录更方便
                </div>
                <div class="wx-login" @click="handleShow(3)">
                    <img src="https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/wx-icon.png" height="81" width="81"/>
                </div>
                <div class="login-type">
                    <p><span :class="{ active: loginType == 2 }" @click="handleShow(2)">密码登录</span></p>
                    <p><span :class="{ active: loginType == 1 }" @click="handleShow(1)">短信登录</span></p>
                </div>
                <login-phone v-if="loginType == 1" ref="phoneLogin"></login-phone>
                <div class="login-ipt" v-if="loginType == 2">
                    <div class="user-name">
                        <div class="phone-wrapper">
                            <img src="https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/sj.png" height="35" width="23"/>
                        </div>
                        <input type="text" v-model="model.username" class="ipt" placeholder="账户名/邮箱/手机号">
                    </div>
                    <div class="user-name">
                        <div class="phone-wrapper">
                            <img src="https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/yzm.png" height="34" width="38"/>
                        </div>
                        <input type="password" v-model="model.password" class="ipt pwd" placeholder="请输入登录密码">
                    </div>
                    <div class="user-name">
                        <input type="text" v-model="model.inputCode" class="ipt pwd" placeholder="请输入验证码">
                        <img :src="randCodeImage" @click="handleChangeCheckCode"/>
                    </div>

                </div>
                <div class="btn-wrapper">
                    <input type="button" value="登录" @click="handleLogin">
                </div>
<!--                <div class="login-desc">-->
<!--                    <a href="#">未收到验证码</a>-->
<!--                    <a href="#">免费注册</a>-->
<!--                </div>-->

            </div>
        </div>
    </div>
</template>

<script>
    import LoginPhone from "@/views/login/LoginPhone"
    import LoginWx from "@/views/login/LoginWx"
    import { mapActions } from 'vuex'
    import api from '@/api/http'
    export default {
        name: "Login",
        components: {
            LoginPhone, LoginWx
        },
        data() {
            return {
                loginType: 2,
                model:{
                    username: '',
                    password: '',
                    inputCode: ''
                },
                currdatetime: '',
                randCodeImage: '',
                requestCodeSuccess: false
            }
        },
        created() {
            this.handleChangeCheckCode();
        },
        methods: {
            ...mapActions(['Login']),
            handleShow(v) {
                this.loginType = v
            },
            handleBack() {
                this.loginType = 2
            },
            /**刷新验证码*/
            handleChangeCheckCode(){
                this.currdatetime = new Date().getTime();
                this.model.inputCode = ''
                api.get(`/sys/randomImage/${this.currdatetime}`).then(res=>{
                    console.log('yzm:',res)
                    if(res.success){
                        this.randCodeImage = res.result
                        this.requestCodeSuccess=true
                    }else{
                        this.$message.error(res.message)
                        this.requestCodeSuccess=false
                    }
                }).catch(()=>{
                    this.requestCodeSuccess=false
                })
            },
            //账号密码登录
            handleLogin(){
                if (this.loginType == 1) {
                    this.$refs.phoneLogin.handleLogin()
                } else {
                    let loginParams = {
                        username: this.model.username,
                        password: this.model.password,
                        captcha: this.model.inputCode,
                        checkKey: this.currdatetime,
                        remember_m: true
                    }
                    this.Login(loginParams).then((res) => {
                        console.log('hererer')
                        this.openNotificationWithIcon('success',res.message)

                        this.$emit('success', res.result)
                        this.$router.push('/home')
                    }).catch((err) => {
                        this.$emit('fail', err)
                        // this.$notification['warning']({
                        //     message: '登录提示',
                        //     description: err.data.message
                        // });
                        this.openNotificationWithIcon('warning',err.data.message)
                    });
                }

            },
            openNotificationWithIcon(type,msg) {
                this.$notification[type]({
                    message: '登录提示',
                    description: msg
                });
            },
            /**
             * 验证字段
             * @param arr
             * @param callback
             */
            validateFields(arr, callback){
                let promiseArray = []
                for(let item of arr){
                    let p = new Promise((resolve, reject) => {
                        this.$refs['form'].validateField(item, (err)=>{
                            if(!err){
                                resolve();
                            }else{
                                reject(err);
                            }
                        })
                    });
                    promiseArray.push(p)
                }
                Promise.all(promiseArray).then(()=>{
                    callback()
                }).catch(err=>{
                    callback(err)
                })
            },
        }
    }
</script>

<style scoped lang="less">
    .login-wrapper {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/login-bg.png");
        background-size: 100% 100%;
        .login-box {
            position: absolute;
            width: 800px;
            height: 510px;

            display: flex;
            background-color: #ffffff;
            top: 50%;
            left: 50%;
            margin-left: -400px;
            margin-top: -255px;
            .left {
                width: 43%;
                height: 100%;
                background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/login-left.jpg");
                background-size: 100% 100%;
            }
            .right {
                position: relative;
                flex: 1;
                height: 100%;
                padding: 80px 30px;
                box-sizing: border-box;
                .sm-login {
                    position: absolute;
                    right: 120px;
                    top: 20px;
                    height: 25px;
                    line-height: 25px;
                    border-radius: 4px;
                    /* 绿2 */
                    border: 1px solid #34CD8B;
                    font-size: 14px;
                    color: #34CD8B;
                }
                .wx-login {
                    position: absolute;
                    right: 20px;
                    top: 20px;
                    cursor: pointer;
                }
                .login-type {
                    display: flex;
                    align-items: center;
                    margin-bottom: 30px;
                    p {
                        flex: 1;
                        span {
                            cursor: pointer;
                            display: inline-block;
                            height: 50px;
                            line-height: 50px;
                            box-sizing: border-box;
                            /* 短信登录 */
                            font-size: 20px;
                            font-weight: 500;
                            letter-spacing: 0em;
                            /* 黑色标题 */
                            color: #333333;
                            &.active {
                                border-bottom: 3px solid #34CD8B;
                            }
                        }
                    }
                }
                .login-ipt {
                    .user-name {
                        /* 矩形 419 */
                        border-radius: 4px;
                        /* White */
                        background: #FFFFFF;
                        /* 灰色 简介类 */
                        border: 1px solid #999999;
                        margin-bottom: 20px;
                        display: flex;
                        height: 50px;
                        align-items: center;
                        .phone-wrapper {
                            width: 50px;
                            height: 100%;
                            background-color: #CCCCCC;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                        }
                        .phone-promt {
                            width: 40px;
                            text-align: center;
                            line-height: 50px;

                        }
                        .ipt {
                            flex: 1;
                            border: none;
                            outline: none;
                            font-size: 16px;
                            color: #999999;
                            box-sizing: border-box;
                            padding-left: 20px;

                        }

                    }
                }
                .btn-wrapper {
                    padding-bottom: 17px;
                    input {
                        /* 矩形 471 */
                        height: 50px;
                        line-height: 50px;
                        width: 100%;
                        border-radius: 4px;
                        /* 绿2 */
                        background: #34CD8B;
                        color: #FFFFFF;
                        border: none;
                        outline: none;
                        font-size: 20px;
                        cursor: pointer;
                    }
                }
                .login-desc {
                    width: 100%;
                    display: flex;
                    a {
                        display: inline-block;
                        flex: 1;
                        font-size: 16px;
                        color: #666666;
                        text-decoration: none;
                        &:last-of-type {
                            text-align: right;
                        }
                    }
                }
            }
            .right-wx {
                flex: 1;
                height: 100%;
            }
        }
    }
</style>
