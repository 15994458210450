<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  import { getAllDictItems } from '@/api/home'
  export default {

    created() {
      getAllDictItems().then(res => {
        console.log('dict:', res)
        this.$ls.set('UI_CACHE_DB_DICT_DATA', res.result)
      })
    }

  }
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}



</style>
