import api from '@/api/http'

export function getLatestActivityList({ pageNo, pageSize, activityState, activityType, departField } = {}) {
    return api.get(`/undp/undpActivity/list?column=createTime&order=desc&pageNo=${pageNo}&pageSize=${pageSize}`,{activityState,activityType,departField})
}
export function getDetail(id) {
    return api.get(`/undp/undpActivity/queryById?id=${id}`)
}

export function getTicketUsers(activityId) {
    return api.get(`/undp/undpTicketUser/listByActivityId?activityId=${activityId}&pageNo=1&pageSize=7`)
}

export function getAllDictItems() {
    return api.get('/sys/dict/queryAllDictItems')
}
export function getTicketList(id) {
    return api.get(`/undp/undpActivity/queryUndpTicketByMainId?id=${id}`)
}

export function getDepartList(idList) {
    return api.get(`/undp/undpDepart/list?idList=${idList}`)
}
export function getVideos(activityId) {
    return api.get(`/undp/undpActivityVideo/list?activityId=${activityId}&pageSize=100`)
}
