<template>
    <div class="tecnology">
        <undp-header2></undp-header2>
        <div class="banner">
            <div class="undp-content-wrapper">
                <div class="title-wrapper">
                    <p class="title">建筑报告</p>
                    <p class="sub-title">Construction report</p>
                </div>
            </div>
        </div>
        <div class="search-wrapper">
            <div class="undp-content-wrapper">
                <div class="item">
                    <p class="title">建筑类别：</p>
                    <div>
                        <a
                                href="javascript:void(0)"
                                :class="{'active':item.value == queryModel.buildCategory }"
                                v-for="(item,i) in dtBuildCategory"
                                :key="i"
                                @click="handleField(item.value)"
                        >{{ item.title }}</a>
                    </div>
                </div>
                <div class="item">
                    <p class="title">状态：</p>
                    <div>
                        <a href="javascript:void(0)"
                           :class="{'active':item.value == queryModel.buildState }"
                           v-for="(item,i) in dtBuildState"
                           :key="i"
                           @click="handleType(item.value)">{{ item.title }}</a>
                    </div>

                </div>
                <hr style="border: 1px solid rgba(0,0,0,0.08)">
                <div class="btn-wrapper">
                    <input type="button" value="查询" @click="handleQuery">
                </div>
            </div>
        </div>
        <div class="latest-tecnology">
            <div class="undp-content-wrapper">
                <div class="tecnolog-list">
                    <div class="item" v-for="(item,i) in list" :key="i" @click="toDetail(item.id)">
                        <div class="cover" :style="{'background-image':'url('+coverUrl(item.img)+')'}"></div>
                        <p class="title">{{ item.buildName }}</p>
                        <div class="sub-title">
                            <div class="sub-item">
                                建筑类型：{{ item.buildCategory_dictText }}
                            </div>
                            <div class="sub-item">
                                建筑面积：{{ item.buildArea }} ㎡
                            </div>
                            <div class="sub-item">
                                供热方式：{{ item.thermalMode_dictText }}
                            </div>
                            <div class="sub-item">
                                气候区域：{{ item.climateZoning1_dictText }} {{ item.climateZoning2_dictText }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <undp-footer></undp-footer>
        <div v-if="loading" style="position: fixed;left: 0;top: 0;width:100%;height:100%;background: rgba(0,0,0,0.3);">
            <a-spin :spinning="loading" style="position: absolute;left: 50%;top: 50%;" tip="Loading..."></a-spin>
        </div>
    </div>
</template>

<script>
    import UndpHeader2 from "@/views/common/UndpHeader2"
    import UndpFooter from "@/views/common/UndpFooter"
    import {getBuildingList} from "@/api/building"
    import { mapGetters } from 'vuex'
    import { formatTime } from '@/common/util/undpUtil'
    export default {
        name: "Building",
        components: {
            UndpHeader2, UndpFooter
        },
        data() {
            return {
                result: {},
                list: [],
                queryModel: {
                    pageNo: 1,
                    pageSize: 10,
                    buildCategory: '',
                    buildState: '',
                },
                loading: true
            }
        },
        computed: {
            ...mapGetters(['dtBuildCategory','dtBuildState']),
            coverUrl() {
                return function (str) {
                    return str?str.split(',')[0]: ''
                }
            }
        },
        mounted() {
            this.loadData()
            this.scroll()
        },
        filters: {
            formatDay(str) {
                const date = new Date(str);
                return date.getDate()
            },
            formatDate2(str) {
                const date = new Date(str);
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                if(month<10){
                    month='0'+month;
                }
                return year + "/" + month
            },
            formatDate3(str) {

                return formatTime(str,5)
            },
        },
        methods: {
            toDetail(v) {
                this.$router.push('/buildingDetail/'+v)
            },
            loadData() {
                this.loading = true
                getBuildingList(this.queryModel).then(res => {
                    if(res.success) {
                        // this.list = res.result.records
                        this.result = res.result
                        this.list = [...this.list, ...res.result.records]
                        this.loading = false
                    }
                })
            },
            handlePage(pageNo) {
                this.queryModel.pageNo = pageNo
                this.loadData()
            },
            handleQuery() {
                this.queryModel.pageNo = 1
                this.list = []
                this.loadData()
            },
            scroll() {
                // let isLoading = true;//是否有数据可以加载
                window.onscroll = async () => {
                    // 距离底部200px时加载一次
                    // let bottomOfWindow = document.documentElement.offsetHeight - document.documentElement.scrollTop - window.innerHeight <= 200;
                    // if (bottomOfWindow) {
                    //     if (this.queryModel.pageNo >= this.result.pages) return
                    //     this.queryModel.pageNo+=1
                    //     this.loadData()
                    // }
                    let bottomOfWindow = document.documentElement.scrollHeight-document.documentElement.scrollTop-document.documentElement.clientHeight
                    if (bottomOfWindow < 310) {
                        if (this.queryModel.pageNo >= this.result.pages) return
                        this.queryModel.pageNo+=1
                        this.loadData()
                    }
                }
            },
            handleField(v) {
                this.queryModel.buildCategory = v
            },
            handleType(v) {
                this.queryModel.buildState = v
            },
        }

    }
</script>

<style scoped lang="less">
    .tecnology {
        .banner {
            height: 450px;
            background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/building/bg.png");
            background-size: 100% 100%;
            .undp-content-wrapper {
                position: relative;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                .title-wrapper {
                    .title {
                        height: 93px;
                        font-family: SourceHanSansCN-Bold;
                        font-size: 64px;
                        font-weight: bold;
                        letter-spacing: 0em;
                        margin-bottom: 0;
                        /* White */
                        color: #FFFFFF;
                    }
                    .sub-title {
                        height: 43px;
                        font-size: 30px;
                        font-weight: normal;
                        text-transform: uppercase;
                        letter-spacing: 0em;

                        /* White */
                        color: #FFFFFF;
                    }
                }
            }
        }
        .search-wrapper {
            padding-top: 60px;
            .item {
                /*display: flex;*/
                /*align-items: center;*/
                margin-bottom: 20px;
                .title {
                    height: 23px;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0em;
                    /* 标题小字 */
                    color: #666666;
                }
                .ipt-wrapper {
                    padding-top: 10px;
                    .ipt {
                        width: 591px;
                        height: 40px;
                        border-radius: 4px;
                        box-sizing: border-box;
                        outline: none;
                        /* Border/Secondary */
                        border: 1px solid #AAAAAA;
                        padding: 0 20px;
                    }
                }
                a {
                    display: inline-block;
                    height: 30px;
                    line-height: 30px;
                    font-family: SourceHanSansCN-Regular;
                    font-size: 16px;
                    font-weight: normal;
                    letter-spacing: 0em;
                    margin-right: 20px;
                    /* Text/Secondary */
                    color: #767676;
                    padding: 0 10px;
                    text-align: center;
                    &.active {
                        /* 绿2 */
                        background: #34CD8B;
                        color: #FFFFFF;
                    }
                }
            }
            .btn-wrapper {
                text-align: right;
                padding-top: 15px;
                input {
                    cursor: pointer;
                    width: 80px;
                    height: 32px;
                    /* 绿2 */
                    background: #34CD8B;
                    /* 筛选 */

                    font-family: SourceHanSansCN-Regular;
                    font-size: 16px;
                    font-weight: normal;
                    letter-spacing: 0em;
                    line-height: 32px;
                    /* White */
                    color: #FFFFFF;
                    border: none;
                    outline: none;
                }
            }
        }
        .latest-tecnology {
            padding-top: 80px;
            .title-wrapper {
                display: flex;
                align-items: center;
                .title {
                    .default-title {
                        height: 58px;
                        font-size: 40px;
                        font-weight: bold;
                        letter-spacing: 0em;
                        color: #3D3D3D;
                        margin-bottom: 0;
                    }
                    .sub-title {
                        height: 23px;
                        font-size: 16px;
                        font-weight: bold;
                        text-transform: uppercase;
                        letter-spacing: 0em;

                        /* 灰色 简介类 */
                        color: #999999;
                    }
                }
                .desc {
                    flex: 1;
                    text-align: right;
                    a {
                        display: inline-block;
                        height: 32px;
                        font-size: 16px;
                        line-height: 32px;
                        font-weight: normal;
                        letter-spacing: 0em;
                        padding: 0 20px;
                        /* 黑色标题 */
                        color: #333333;
                        margin-right: 20px;
                        &:hover {
                            border-radius: 4px;
                            /* 绿2 */
                            background: #34CD8B;
                            /* White */
                            color: #FFFFFF;

                        }
                    }
                }
            }
            .tecnolog-list {
                display: flex;
                flex-wrap: wrap;
                .item {
                    width: 50%;
                    padding: 40px 80px 40px 0;
                    box-sizing: border-box;
                    border-bottom: 1px solid rgba(0,0,0,0.08);
                    cursor: pointer;
                    &:nth-of-type(2n) {
                        padding: 40px 0 40px 80px;
                        border-left: 1px solid rgba(0,0,0,0.08);
                    }
                    .cover {
                        height: 290px;
                        background-size: cover;
                    }
                    .title {
                        padding-top: 20px;
                        height: 60px;
                        font-size: 20px;
                        font-weight: normal;
                        line-height: 30px;
                        letter-spacing: 0em;
                        /* 文字 */
                        color: rgba(0, 0, 0, 0.8);
                    }
                    .sub-title {
                        .sub-item {
                            height: 26px;
                            font-family: SourceHanSansCN-Regular;
                            font-size: 16px;
                            font-weight: normal;
                            line-height: 26px;
                            letter-spacing: 0em;
                            /* 灰色 简介类 */
                            color: #999999;
                        }
                    }

                }
            }
        }

    }

</style>