import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/login/Login"
import Home from "@/views/home/Home"
import FrontTecnology from "@/views/tecnology/FrontTecnology"
import NotFund from '@/views/NotFund'
import TecnologyDetail from "@/views/tecnology/TecnologyDetail"
import Preview from "@/views/common/Preview"
import Activity from "@/views/activity/Activity"
import ActivityDetail from "@/views/activity/ActivityDetail"
import Project from "@/views/project/Project"
import ProjectDetail from "@/views/project/ProjectDetail"
import Policy from "@/views/policy/Policy"
import PolicyDetail from "@/views/policy/PolicyDetail"
import Building from "@/views/building/Building"
import BuildingDetail from "@/views/building/BuildingDetail"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/tecnology',
    name: 'tecnology',
    component: FrontTecnology
  },
  {
    path: '/tecnologyDetail/:id',
    name: 'tecnologyDetail',
    component: TecnologyDetail
  },
  {
    path: '/activity',
    name: 'activity',
    component: Activity
  },
  {
    path: '/activityDetail/:id',
    name: 'activityDetail',
    component: ActivityDetail
  },
  {
    path: '/project',
    name: 'project',
    component: Project
  },
  {
    path: '/projectDetail/:id',
    name: 'projectDetail',
    component: ProjectDetail
  },
  {
    path: '/policy',
    name: 'policy',
    component: Policy
  },
  {
    path: '/policyDetail/:id',
    name: 'policyDetail',
    component: PolicyDetail
  },
  {
    path: '/building',
    name: 'building',
    component: Building
  },
  {
    path: '/buildingDetail/:id',
    name: 'buildingDetail',
    component: BuildingDetail
  },
  {
    path: '/preview',
    name: 'preview',
    component: Preview
  },
  {
    path: '*',
    name: 'notfund',
    component: NotFund
  },
]

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to,from,savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
