<template>
    <div class="tecnology">
        <undp-header2></undp-header2>
        <div class="banner">
            <div class="undp-content-wrapper">
                <div class="title-wrapper">
                    <p class="title">行业政策</p>
                    <p class="sub-title">Industry policy</p>
                </div>
            </div>
        </div>
        <div class="search-wrapper">
            <div class="undp-content-wrapper">
<!--                <div class="item">-->
<!--                    <p class="title">行业领域：</p>-->
<!--                    <div>-->
<!--                        <a-->
<!--                                href="javascript:void(0)"-->
<!--                                :class="{'active':item.value == queryModel.dataField }"-->
<!--                                v-for="(item,i) in dtField"-->
<!--                                :key="i"-->
<!--                                @click="handleField(item.value)"-->
<!--                        >{{ item.title }}</a>-->
<!--                    </div>-->
<!--                </div>-->
                <div class="item">
                    <span class="title">关键字：</span>
                    <div class="ipt-wrapper">
                        <input type="text" v-model="queryModel.keyword" class="ipt" placeholder="请输入关键字">
                    </div>
                </div>
                <hr style="border: 1px solid rgba(0,0,0,0.08)">
                <div class="btn-wrapper">
                    <input type="button" value="查询" @click="handleQuery">
                </div>
            </div>
        </div>
        <div class="latest-tecnology">
            <div class="undp-content-wrapper">
                <div class="menu-wrapper">
                    <a :class="{active: item.value == queryModel.policyType,'menu-item':true}" v-for="(item,i) in dtPolicyType" :key="i" @click="handleField(item.value)">
                        <img src="https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/line.png" alt="">
                        <span>{{ item.title }}</span>
                    </a>
                </div>
                <div class="activity-list">
                    <div class="item" v-for="(item,i) in list" :key="i" @click="toDetail(item.id)">
<!--                        <div class="cover" :style="{'background-image':'url('+item.titleImg+')'}"></div>-->
                        <div class="content">
                            <div class="title-wrapper">
                                <p class="title">{{ item.policyName }}</p>
                                <span class="time">{{ item.createTime }}</span>
                            </div>
                            <p class="desc">
                                {{ item.papers | glHtml }}
                            </p>
                            <p>关键字：{{ item.keyword }}</p>
                            <p style="color: #34CD8B">{{ item.policyType_dictText }}</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
<!--        <div class="pager" v-if="result.pages>1">-->
<!--            <div class="undp-content-wrapper">-->
<!--                <a href="#" @click="handlePage(item)" :class="{active: result.current == item}" v-for="(item,i) in result.pages">{{item}}</a>-->
<!--            </div>-->
<!--        </div>-->
        <undp-footer></undp-footer>
        <div v-if="loading" style="position: fixed;left: 0;top: 0;width:100%;height:100%;background: rgba(0,0,0,0.3);">
            <a-spin :spinning="loading" style="position: absolute;left: 50%;top: 50%;" tip="Loading..."></a-spin>
        </div>
    </div>
</template>

<script>
    import UndpHeader2 from "@/views/common/UndpHeader2"
    import UndpFooter from "@/views/common/UndpFooter"
    import {getPolicyList} from "@/api/policy"
    import { mapGetters } from 'vuex'
    import { formatTime } from '@/common/util/undpUtil'
    export default {
        name: "Policy",
        components: {
            UndpHeader2, UndpFooter
        },
        data() {
            return {
                result: {},
                list: [],
                queryModel: {
                    pageNo: 1,
                    pageSize: 10,
                    policyType: '',
                    keyword: ''
                },
                loading: true
            }
        },
        computed: {
            ...mapGetters(['dtPolicyType']),
        },
        mounted() {
            this.loadData()
            this.scroll()
        },
        filters: {
            formatDay(str) {
                const date = new Date(str);
                return date.getDate()
            },
            formatDate2(str) {
                const date = new Date(str);
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                if(month<10){
                    month='0'+month;
                }
                return year + "/" + month
            },
            formatDate3(str) {

                return formatTime(str,5)
            },
            glHtml(str) {
                var reg = /(<([^>]+)>)/ig
                return str.replace(reg,'')
            }
        },
        methods: {
            toDetail(v) {
                this.$router.push('/policyDetail/'+v)
            },
            loadData() {
                this.loading = true
                getPolicyList(this.queryModel).then(res => {
                    if(res.success) {
                        // this.list = res.result.records
                        this.result = res.result
                        this.list = [...this.list, ...res.result.records]
                         this.list.forEach(item=>{
                            console.log(item.createTime.slice(0,10));
                            let createTime  = item.createTime.slice(0,10)
                            item.createTime = createTime
                        })
                        this.loading = false
                    }
                })
            },
            handlePage(pageNo) {
                this.queryModel.pageNo = pageNo
                this.loadData()
            },
            handleQuery() {
                this.queryModel.pageNo = 1
                this.list = []
                this.loadData()
            },
            scroll() {
                // let isLoading = true;//是否有数据可以加载
                window.onscroll = async () => {
                    // 距离底部200px时加载一次
                    // let bottomOfWindow = document.documentElement.offsetHeight - document.documentElement.scrollTop - window.innerHeight <= 200;
                    // if (bottomOfWindow) {
                    //     if (this.queryModel.pageNo >= this.result.pages) return
                    //     this.queryModel.pageNo+=1
                    //     this.loadData()
                    // }
                    let bottomOfWindow = document.documentElement.scrollHeight-document.documentElement.scrollTop-document.documentElement.clientHeight
                    if (bottomOfWindow < 310) {
                        if (this.queryModel.pageNo >= this.result.pages) return
                        this.queryModel.pageNo+=1
                        this.loadData()
                    }
                }
            },
            handleField(v) {
                this.queryModel.policyType = v
                this.handleQuery()
            },
        }

    }
</script>

<style scoped lang="less">
    .tecnology {
        .banner {
            height: 450px;
            background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/policy/bg.png");
            background-size: 100% 100%;
            .undp-content-wrapper {
                position: relative;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                .title-wrapper {
                    .title {
                        height: 93px;
                        font-family: SourceHanSansCN-Bold;
                        font-size: 64px;
                        font-weight: bold;
                        letter-spacing: 0em;
                        margin-bottom: 0;
                        /* White */
                        color: #FFFFFF;
                    }
                    .sub-title {
                        height: 43px;
                        font-size: 30px;
                        font-weight: normal;
                        text-transform: uppercase;
                        letter-spacing: 0em;

                        /* White */
                        color: #FFFFFF;
                    }
                }
            }
        }
        .search-wrapper {
            padding-top: 60px;
            .item {
                /*display: flex;*/
                /*align-items: center;*/
                margin-bottom: 20px;
                .title {
                    height: 23px;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0em;
                    /* 标题小字 */
                    color: #666666;
                }
                .ipt-wrapper {
                    padding-top: 10px;
                    .ipt {
                        width: 591px;
                        height: 40px;
                        border-radius: 4px;
                        box-sizing: border-box;
                        outline: none;
                        /* Border/Secondary */
                        border: 1px solid #AAAAAA;
                        padding: 0 20px;
                    }
                }
                a {
                    display: inline-block;
                    height: 30px;
                    line-height: 30px;
                    font-family: SourceHanSansCN-Regular;
                    font-size: 16px;
                    font-weight: normal;
                    letter-spacing: 0em;
                    margin-right: 20px;
                    /* Text/Secondary */
                    color: #767676;
                    padding: 0 10px;
                    text-align: center;
                    &.active {
                        /* 绿2 */
                        background: #34CD8B;
                        color: #FFFFFF;
                    }
                }
            }
            .btn-wrapper {
                text-align: right;
                padding-top: 15px;
                input {
                    cursor: pointer;
                    width: 80px;
                    height: 32px;
                    /* 绿2 */
                    background: #34CD8B;
                    /* 筛选 */

                    font-family: SourceHanSansCN-Regular;
                    font-size: 16px;
                    font-weight: normal;
                    letter-spacing: 0em;
                    line-height: 32px;
                    /* White */
                    color: #FFFFFF;
                    border: none;
                    outline: none;
                }
            }
        }
        .latest-tecnology {
            padding-top: 80px;
            .undp-content-wrapper {
                display: flex;
                .menu-wrapper {
                    width: 230px;
                    .menu-item {
                        display: block;
                        height: 60px;
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid #CCCCCC;
                        box-sizing: border-box;
                        span {
                            display: inline-block;
                            /* 全部 */
                            height: 17px;
                            font-family: SourceHanSansCN-Medium;
                            font-size: 20px;
                            font-weight: 500;
                            line-height: 17px;
                            letter-spacing: 0em;
                            /* 绿2 */
                            color: #333333;
                            margin-left: 20px;
                        }
                        img {
                            visibility: hidden;
                        }
                        &.active {
                            span {
                                color: #34CD8B;
                            }
                            img {
                                visibility: visible;
                            }
                        }
                        &:hover {
                            img {
                                visibility: visible;
                            }
                            span {
                                color: #34CD8B;
                            }
                        }
                    }
                }
                .activity-list {
                    flex: 1;
                    display: flex;
                    flex-wrap: wrap;
                    margin-left: 50px;
                    .item {
                        margin: 0 0 40px 0;
                        width: 100%;
                        box-sizing: border-box;
                        border: 1px solid rgba(0,0,0,0.08);
                        cursor: pointer;
                        padding: 40px 20px;
                        display: flex;
                        &:nth-of-type(2n) {
                            margin-right: 0;
                        }
                        &:hover {
                            border-color: #34CD8B;
                        }
                        .cover {
                            width: 228px;
                            height: 158px;
                            background-size: cover;
                        }
                        .content {
                            flex: 1;
                            padding: 0 20px;
                            box-sizing: border-box;
                            .title-wrapper {
                                width: 100%;
                                display: flex;
                                .title {
                                    flex: 1;
                                    max-height: 58px;
                                    font-family: SourceHanSansCN-Regular;
                                    font-size: 20px;
                                    font-weight: normal;
                                    letter-spacing: 0em;
                                    line-height: 29px;
                                    /* 黑色标题 */
                                    color: #333333;
                                }
                                .time {
                                    width: 120px;
                                    line-height: 29px;
                                    font-family: AlibabaPuHuiTiM;
                                    font-size: 20px;
                                    font-weight: 500;
                                    letter-spacing: 0em;

                                    /* 灰色 简介类 */
                                    color: #999999;
                                }
                            }

                            .desc {
                                /* 简介： */
                                height: 78px;
                                font-family: SourceHanSansCN-Regular;
                                font-size: 16px;
                                font-weight: normal;
                                line-height: 26px;
                                letter-spacing: 0em;
                                /* 灰色 简介类 */
                                color: #999999;
                                width: 100%;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 4;
                                word-break: break-all;
                            }

                        }

                    }
                }

            }

        }
        .pager {
            padding: 60px 0 100px 0;
            a {
                display: inline-block;
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                font-size: 20px;
                font-weight: normal;
                letter-spacing: 0em;
                /* 灰色 简介类 */
                color: #999999;
                margin-right: 10px;
                &.active {
                    background-color: #34CD8B;
                    color: #FFFFFF;
                }
            }
        }
    }

</style>