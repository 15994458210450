import api from '@/api/http'

export function getInnovateList({ pageNo, pageSize } = {}) {
    return api.get('/undp/undpInnovate/list',{ pageNo, pageSize,column: 'createTime',order: 'desc' })
}
export function getActivityList({ pageNo, pageSize } = {}) {
    return api.get('/undp/undpActivity/list',{ pageNo, pageSize,column: 'createTime',order: 'desc' })
}
export function getPolicyList({ pageNo, pageSize } = {}) {
    return api.get('/undp/undpPolicy/list',{ pageNo, pageSize,column: 'createTime',order: 'desc' })
}
export function getDepartList({ pageNo, pageSize } = {}) {
    return api.get('/undp/undpDepart/list',{ pageNo, pageSize,column: 'createTime',order: 'desc' })
}

export function getAllDictItems() {
    return api.get('/sys/dict/queryAllDictItems')
}