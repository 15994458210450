<template>
    <div class="tecnology-detail">
        <div class="detail-header">
            <undp-header></undp-header>
        </div>
        <div class="detail-content">
            <div class="undp-content-wrapper">
                <div class="activity-header">
                    <div class="cover" :style="{'background-image':'url('+coverUrl(dt.img)+')'}">
                    </div>
                    <div class="header-content">
                        <p class="title">{{ dt.projectName }}</p>
<!--                        <div class="subtitle-wrapper">-->
<!--                            <span>关联领域</span><span>绿色建筑</span>-->
<!--                        </div>-->
                        <div class="subtitle-wrapper">
                            <span>项目类别</span><span>{{ projectTypeText }}</span>
                        </div>
                        <div class="subtitle-wrapper">
                            <span>项目批次</span><span>{{ dt.projectBatchYear }} - {{ projectBatchText }}</span>
                        </div>
                        <div class="subtitle-wrapper">
                            <span>起止日期</span><span>{{ dt.startTime | formatDate3 }} - {{ dt.endTime | formatDate3 }}</span>
                        </div>
                        <img v-if="dt.projectState==2" class="project-status" src="https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/project/doing.png" alt="">
                        <img v-if="dt.projectState==3" class="project-status" src="https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/project/finish2.png" alt="">
                    </div>
                </div>
<!--                <div class="pc-wrapper">-->
<!--                    <div class="pc-item">-->
<!--                        <span>项目批次</span>-->
<!--                        <span>{{ dt.projectBatchYear }} - {{ dt.projectBatch_dictText }}第一批</span>-->
<!--                    </div>-->
<!--                    <div class="pc-item">-->
<!--                        <span>起止日期</span>-->
<!--                        <span>{{ dt.startTime | formatDate3 }} - {{ dt.endTime | formatDate3 }}</span>-->
<!--                    </div>-->
<!--                </div>-->
                <div class="organize">
                    <div class="module">
                        <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                        <span>项目承接/承接机构</span>
                    </div>
                    <div class="org-wrapper" v-for="(item,i) in departs" :key="i">
                        <div class="cover" :style="{'background-image':'url('+item.departImg+')'}"></div>
                        <div class="org-content">{{ item.synopsis }}</div>
                    </div>
                </div>
                <div class="content">
                    <div class="module">
                        <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                        <span>项目背景</span>
                    </div>
                    <div v-html="dt.background"></div>
                </div>
                <div class="content">
                    <div class="module">
                        <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                        <span>项目内容</span>
                    </div>
                    <div v-html="dt.content"></div>
                </div>
                <div class="content">
                    <div class="module">
                        <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                        <span>预期效果</span>
                    </div>
                    <div v-html="dt.expect"></div>
                </div>
                <div class="movies">
                    <div class="module">
                        <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                        <span>媒体资料</span>
                    </div>
                    <div>
                        <video v-for="(item,i) in videos" :key="i" controls="true" :src="item.url"></video>
                    </div>
                </div>
                <div class="zl">
                    <div class="module">
                        <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                        <span>文档资料</span>
                    </div>
                    <div class="zl-list">
                        <div class="zl-item" v-for="(item,i) in files" :key="i">
                            <img class="zl-cover" src="https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/tecnology/zl.png" alt="">
                            <div class="zl-content" @click="handleToPreview(item.path)">
                                <p>{{ item.name }}</p>
                                <p>上传时间：{{ item.tm | formatDate3 }}</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="honer">
                    <div class="module">
                        <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                        <span>项目图片</span>
                    </div>
                    <div class="honer-content">
                        <div class="cover" v-for="(item,i) in certificates" :key="i" :style="{'background-image': 'url('+item.url+')'}"></div>
                    </div>

                </div>
                <div class="expert" v-if="false">
                    <div class="module">
                        <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                        <span>专家团队</span>
                    </div>
                    <div class="expert-content">
                        <div class="item">
                            <div class="cover"></div>
                            <div class="expert-wrapper">
                                <p class="expert-name">江亿</p>
                                <p class="expert-desc">中国工程院院士、清华大学建筑节能研究中心主任</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="comment">
                    <div class="comment-title">
                        <p>发表评论</p>
                        <p>comment</p>
                    </div>
                    <div class="comment-ipt">
                        <textarea placeholder="发表评论" v-model="commentContent"></textarea>
                        <p class="rt">
                            <input class="btn-comment" type="button" value="发表评论" @click="handlePublish">
                        </p>
                    </div>
                    <div class="comment-list">
                        <div class="comment-item" v-for="(item,i) in comments" :key="i">
                            <div class="cover" :style="{'background-image':'url('+item.avatar}"></div>
                            <div class="comment-content">
                                <div class="top">
                                    <span>{{ item.createBy_dictText }}</span>
                                    <span>{{ item.createTime }}</span>
                                    <span v-if="item.isSelf" class="comment-delete" @click="handleDelete(item.id)">删除</span>
                                </div>
                                <p class="bottom">{{ item.content }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <undp-footer></undp-footer>
        </div>



    </div>
</template>

<script>
    import UndpHeader from "@/views/common/UndpHeader"
    import UndpFooter from "@/views/common/UndpFooter"
    import {getDetail,getComments,addComment, deleteComment } from "@/api/project"
    import { getDepartList } from '@/api/activity'
    import { formatTime } from '@/common/util/undpUtil'
    import {mapGetters} from "vuex"
    import { openNotificationWithIcon } from '@/common/util/undpUtil'
    import { USER_NAME } from '@/store/mutation-types'
    export default {
        name: "ProjectDetail",
        components: {
            UndpHeader, UndpFooter
        },
        data() {
          return {
              id: '',
              dt: {},
              videos: [],
              certificates: [],
              files: [],
              ticketUsers: [],
              total: 0,
              projectTypeText: '',
              projectBatchText: '',
              comments: [],
              commentContent: '',
              departs: []
          }
        },
        computed: {
            ...mapGetters(['dtProjectType','dtProjectState','dtProjectBatch']),
            coverUrl() {
                return function (str) {
                    return str?str.split(',')[0]: ''
                }
            }
        },
        filters: {
            formatDate3(str) {
                return formatTime(str,3)
            },
        },
        mounted() {
            this.loadData()
            this.initComments()
        },
        methods: {
            isLogin() {
                const token = this.$ls.get('Access-Token')
                if (!token) {
                    this.$notification['warning']({
                        message: '提示',
                        description: '请先登录'
                    })
                    return false
                }
                return true
            },
            handleDelete(id) {
                if (!this.isLogin()) return
                deleteComment(id).then(res => {
                    if(res.success) {
                        this.$notification['success']({
                            message: '提示',
                            description: '删除成功'
                        })
                        this.initComments()
                    }
                })
            },
            handlePublish() {
                if (!this.isLogin()) return
                addComment({ projectId: this.id, likes: 0, content: this.commentContent, commentStatus: '1' }).then(res => {
                    if(res.success) {
                        this.$notification['success']({
                            message: '提示',
                            description: '发表成功'
                        })
                        this.initComments()
                        setTimeout(() => {
                            this.commentContent = ''
                        },2000)
                    }
                })
            },
            initComments() {
                const userName = this.$ls.get(USER_NAME)
                getComments({pageNo: 1,pageSize: 1000,projectId: this.id}).then(res => {
                    if(res.result && res.result.records) {
                        this.comments = res.result.records
                        this.comments.forEach(v => {
                            this.$set(v,'isSelf',userName==v.createBy)
                        })
                    }
                })
            },
            loadData() {
                this.id = this.$route.params.id
                getDetail(this.id).then(res => {
                    console.log('res:', res)
                    this.dt = res.result
                    this.projectTypeText = this.dtProjectType.find(v => v.value == this.dt.projectType).text
                    this.projectBatchText = this.dtProjectBatch.find(v => v.value == this.dt.projectBatch).text
                    if(this.dt.video) {
                        this.dt.video.split(',').forEach(v => {
                            const temp = { url: v, playing: false }
                            this.videos.push(temp)
                        })
                    }
                    if(this.dt.img) {
                        this.dt.img.split(',').forEach(v => {
                            const temp = { url: v }
                            this.certificates.push(temp)
                        })
                    }
                    if(this.dt.files) {
                        this.dt.files.split(',').forEach(v => {
                            let n = v.substring(v.lastIndexOf('/')+1,v.lastIndexOf('_'))
                            let temp = { name: n, path: v,tm: this.dt.releaseTime }
                            this.files.push(temp)

                        })
                    }
                    if (this.dt.projectDepart) {
                        this.loadDeparts(this.dt.projectDepart.split(','))
                    }

                })
            },
            loadDeparts(ary) {
                getDepartList(ary).then(res => {
                    this.departs = res.result.records
                })
            },
            handleToPreview(path) {
                this.$ls.set('path', path)
                window.open('/preview','_blank')
            }

        }
    }
</script>

<style scoped lang="less">
    .tecnology-detail {
        .detail-header {
            height: 350px;
            background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/tecnology/dtbg.png");
            background-size: 100% auto;
        }
        .detail-content {
            position: absolute;
            left: 0;
            top: 200px;
            width: 100%;
            box-sizing: border-box;
            .undp-content-wrapper {
                background-color: #FFFFFF;
                box-sizing: border-box;
                padding: 80px 40px 40px;
                .activity-header {
                    display: flex;
                    .cover {
                        width: 447px;
                        height: 250px;
                        background-size: 100% 100%;
                    }
                    .header-content {
                        flex: 1;
                        padding: 0 0 0 50px;
                        box-sizing: border-box;
                        border-bottom: none;
                        position: relative;
                        .title {
                            max-height: 84px;
                            font-family: SourceHanSansCN-Medium;
                            font-size: 32px;
                            font-weight: 500;
                            line-height: 42px;
                            letter-spacing: 0em;

                            /* 黑色标题 */
                            color: #333333;
                        }
                        .subtitle-wrapper {
                            margin-bottom: 10px;
                            span:first-of-type {
                                display: inline-block;
                                padding: 0 8px;
                                width: 80px;
                                height: 30px;
                                /* 绿2 */
                                background: #34CD8B;
                                /* 关联领域 */
                                font-size: 16px;
                                font-weight: normal;
                                line-height: 30px;
                                text-transform: uppercase;
                                letter-spacing: 0em;

                                /* White */
                                color: #FFFFFF;
                                margin-right: 20px;
                            }
                        }
                        .project-status {
                            position: absolute;
                            right: 0;
                            bottom: 60px;
                        }
                    }
                }
                .pc-wrapper {
                    padding: 40px 0;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
                    display: flex;
                    .pc-item {
                        width: 447px;
                        text-align: right;
                        span:first-of-type {
                            display: inline-block;
                            width: 117px;
                            height: 54px;
                            /* 绿2 */
                            background: #34CD8B;
                            /* 项目加密 */
                            font-size: 20px;
                            font-weight: 500;
                            line-height: 34px;
                            text-transform: uppercase;
                            letter-spacing: 0em;
                            /* White */
                            color: #FFFFFF;
                            padding: 10px;
                            box-sizing:border-box;
                            text-align: center;
                        }
                        span:last-of-type {
                            display: inline-block;
                            height: 54px;
                            line-height: 54px;
                            /* 灰 */
                            background: #F5F5F5;
                            font-size: 20px;
                            font-weight: normal;
                            line-height: 54px;
                            text-transform: uppercase;
                            letter-spacing: 0em;

                            /* 标题小字 */
                            color: #666666;
                            text-align: center;
                            padding: 0 10px;
                        }
                    }
                    .pc-item:last-of-type {
                        flex: 1;
                        padding-left: 50px;
                        box-sizing: border-box;
                        text-align: left;
                    }
                }
                .module {
                    display: flex;
                    align-items: center;
                    height: 30px;
                    margin-bottom: 40px;
                    span {
                        height: 30px;
                        font-family: SourceHanSansCN-Medium;
                        font-size: 20px;
                        font-weight: 500;
                        letter-spacing: 0em;
                        line-height: 30px;
                        /* 黑色标题 */
                        color: #333333;
                        margin-left: 8px;
                    }
                }
                .content {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                }
                .movies {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                }
                .zl {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                    .zl-list {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        .zl-item {
                            width: calc((100% - 60px) / 2);
                            height: 150px;
                            margin: 0 60px 20px 0;
                            background: #F5F5F5;
                            border: 1px solid rgba(0, 0, 0, 0.08);
                            display: flex;
                            align-items: center;
                            box-sizing: border-box;
                            padding: 0 30px;
                            cursor: pointer;
                            &:nth-of-type(2n) {
                                margin-right: 0;
                            }
                            .zl-cover {
                                margin-right: 30px;
                            }
                            .zl-content {
                                p:first-of-type {
                                    height: 65px;
                                    font-family: SourceHanSansCN-Regular;
                                    font-size: 20px;
                                    font-weight: normal;
                                    line-height: 30px;
                                    letter-spacing: 0em;

                                    /* 文字 */
                                    color: rgba(0, 0, 0, 0.8);
                                }
                                p:last-of-type {
                                    height: 23px;
                                    font-family: SourceHanSansCN-Regular;
                                    font-size: 16px;
                                    font-weight: normal;
                                    letter-spacing: 0em;

                                    /* 灰色 简介类 */
                                    color: #999999;
                                }
                            }
                        }
                    }
                }
                .honer {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                    .honer-content {
                        display: flex;
                        flex-wrap: wrap;
                        .cover {
                            width: calc((100% - 60px) / 4);
                            padding-bottom: 30%;
                            margin: 0 20px 20px 0;
                            background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/tecnology/zs.jpeg");
                            background-size: 100% 100%;
                            &:nth-of-type(4n) {
                                margin-right: 0;
                            }
                        }
                    }
                }
                .organize {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                    .org-wrapper {
                        display: flex;
                        align-items: center;
                        margin-bottom: 20px;
                        .cover {
                            width: 120px;
                            height: 120px;
                            margin-right: 50px;
                            background-size: 100% 100%;
                        }
                        .org-content {
                            flex: 1;
                        }
                    }

                }
                .expert {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                    .expert-content {
                        display: flex;
                        flex-wrap: wrap;
                        .item {
                            width: calc((100% - 120px) / 3);
                            margin: 0 60px 30px 0;
                            border: 1px solid #CCCCCC;
                            box-sizing: border-box;
                            &:nth-of-type(3n) {
                                margin-right: 0;
                            }
                            .cover {
                                width: 100%;
                                padding-bottom: 100%;
                                background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/tecnology/expert.png");
                                background-size: cover;
                            }
                            .expert-wrapper {
                                padding: 20px;
                                box-sizing: border-box;
                                background-color: #F5F5F5;
                                .expert-name {
                                    height: 30px;
                                    font-family: SourceHanSansCN-Regular;
                                    font-size: 20px;
                                    font-weight: normal;
                                    line-height: 30px;
                                    letter-spacing: 0em;
                                    /* 黑色标题 */
                                    color: #333333;
                                    margin-bottom: 15px;
                                }
                                .expert-desc {
                                    height: 52px;
                                    font-family: SourceHanSansCN-Regular;
                                    font-size: 16px;
                                    font-weight: normal;
                                    line-height: 26px;
                                    letter-spacing: 0em;

                                    /* 灰色 简介类 */
                                    color: #999999;
                                }
                            }
                        }
                    }

                }
                .comment {
                    padding: 40px 0;
                    .comment-title {
                        padding-bottom: 40px;
                        p:first-of-type {
                            height: 29px;
                            font-family: SourceHanSansCN-Medium;
                            font-size: 20px;
                            font-weight: 500;
                            letter-spacing: 0em;

                            /* 黑色标题 */
                            color: #333333;
                        }
                        p:last-of-type {
                            height: 20px;
                            font-family: SourceHanSansCN-Regular;
                            font-size: 14px;
                            font-weight: normal;
                            text-transform: uppercase;
                            letter-spacing: 0em;

                            /* 标题小字 */
                            color: #666666;
                        }
                    }
                    .comment-ipt {
                        border-top: 1px solid #CCCCCC;
                        border-bottom: 1px solid #CCCCCC;
                        box-sizing: border-box;
                        padding: 40px 0;
                        textarea {
                            width: 100%;
                            height: 84px;
                            outline: none;
                            /* 灰 */
                            background: #F5F5F5;
                            border: 1px solid rgba(0, 0, 0, 0.08);
                            padding: 20px;
                            box-sizing: border-box;
                        }
                        .btn-comment {
                            width: 80px;
                            height: 32px;
                            line-height: 32px;
                            /* 绿2 */
                            background: #34CD8B;
                            font-family: SourceHanSansCN-Regular;
                            font-size: 16px;
                            font-weight: normal;
                            letter-spacing: 0em;
                            /* White */
                            color: #FFFFFF;
                            border: none;
                            margin-top: 20px;
                            cursor: pointer;
                        }
                    }
                    .comment-list {
                        padding-top: 20px;
                        .comment-item {
                            height: 60px;
                            display: flex;
                            align-items: center;
                            margin-bottom: 20px;
                            .cover {
                                width: 50px;
                                height: 50px;
                                background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/tecnology/head.png");
                                background-size: 100% 100%;
                                margin-right: 25px;
                            }
                            .comment-content {
                                flex: 1;
                                .top {
                                    span {
                                        &:first-of-type {
                                            height: 30px;
                                            font-family: SourceHanSansCN-Regular;
                                            font-size: 16px;
                                            font-weight: normal;
                                            line-height: 30px;
                                            letter-spacing: 0em;
                                            /* 标题小字 */
                                            color: #666666;
                                            margin-right: 20px;
                                        }
                                        &:nth-of-type(2) {
                                            height: 30px;
                                            font-family: SourceHanSansCN-Regular;
                                            font-size: 14px;
                                            font-weight: normal;
                                            line-height: 30px;
                                            letter-spacing: 0em;

                                            /* 灰色 简介类 */
                                            color: #999999;
                                        }
                                    }
                                    .comment-delete {
                                        cursor: pointer;
                                        margin-left: 10px;
                                    }
                                }
                                .bottom {
                                    height: 30px;
                                    font-family: SourceHanSansCN-Regular;
                                    font-size: 16px;
                                    font-weight: normal;
                                    line-height: 30px;
                                    letter-spacing: 0em;
                                    /* 标题小字 */
                                    color: #666666;
                                }
                            }
                        }
                    }
                }
            }

        }

    }
</style>
