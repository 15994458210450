<template>
    <div class="tecnology-detail">
        <div class="detail-header">
            <undp-header></undp-header>
        </div>
        <div class="detail-content">
            <div class="undp-content-wrapper">
                <div class="activity-header">
                    <div class="cover" :style="{'background-image':'url('+coverUrl(dt.img)+')'}">
                    </div>
                    <div class="header-content">
                        <div class="header-wrapper">
                            <span>建筑名称</span><span>{{ dt.buildName }}</span>
                        </div>
                        <div class="header-wrapper">
                            <span>建筑类别</span><span>{{ buildCategoryText }}</span>
                        </div>
                        <div class="header-wrapper">
                            <span>建筑面积</span><span>{{ dt.buildArea }}</span>
                        </div>
                        <div class="header-wrapper">
                            <span>供冷方式</span><span>{{ coldModeText }}</span>
                        </div>
                        <div class="header-wrapper">
                            <span>供热方式</span><span>{{ thermalModeText }}</span>
                        </div>
                        <div class="header-wrapper">
                            <span>供暖指标</span><span>{{ thermalIndexText }}</span>
                        </div>
                        <div class="header-wrapper">
                            <span>采暖面积</span><span>{{ dt.thermalArea }} ㎡</span>
                        </div>
                        <div class="header-wrapper">
                            <span>空调面积</span><span>{{ dt.conditionerArea }} ㎡</span>
                        </div>
                    </div>
                </div>
                <div class="jx-wrapper">
                    <div class="subtitle-wrapper">
                        <span>电力公司</span><span>{{ powerCompanyText }}</span>
                    </div>
                    <div class="subtitle-wrapper">
                        <span>供热公司</span><span>{{ thermalCompanyText }}</span>
                    </div>
                    <div class="subtitle-wrapper">
                        <span>地上/下</span><span>{{ dt.aboveFloor }} / {{ dt.belowFloor }}</span>
                    </div>
                    <div class="subtitle-wrapper">
                        <span>标准层高</span><span>{{ dt.floorHeight }} 米</span>
                    </div>
                    <div class="subtitle-wrapper">
                        <span>能源审计</span><span>{{ energyAuditText }}</span>
                    </div>
                    <div class="subtitle-wrapper">
                        <span>建筑地址</span><span>{{ $Area.getText(dt.address) }}</span>
                    </div>
                </div>
                <div class="content">
                    <div class="module">
                        <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                        <span>建筑介绍</span>
                    </div>
                    <div v-html="dt.synopsis"></div>
                </div>
                <div class="honer">
                    <div class="module">
                        <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                        <span>建筑图集</span>
                    </div>
                    <div class="honer-content">
                        <div class="cover" v-for="(item,i) in imgs" :key="i" :style="{'background-image': 'url('+item.url+')'}"></div>
                    </div>

                </div>
                <div class="honer">
                    <div class="module">
                        <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                        <span>奖项荣誉</span>
                    </div>
                    <div class="honer-content">
                        <div class="cover" v-for="(item,i) in prizes" :key="i" :style="{'background-image': 'url('+item.url+')'}"></div>
                    </div>

                </div>
                <div class="content">
                    <div class="module">
                        <img src="../../assets/client/tecnology/line.png" height="16" width="4"/>
                        <span>应用技术</span>
                    </div>
                    <div>
                        <img src="https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/building/yy.png" alt="" style="width: 100%">
                    </div>
                </div>
            </div>
            <undp-footer></undp-footer>
        </div>



    </div>
</template>

<script>
    import UndpHeader from "@/views/common/UndpHeader"
    import UndpFooter from "@/views/common/UndpFooter"
    import {getDetail,getIcons} from "@/api/building"
    import { formatTime } from '@/common/util/undpUtil'
    import {mapGetters} from "vuex";
    export default {
        name: "BuildingDetail",
        components: {
            UndpHeader, UndpFooter
        },
        data() {
          return {
              dt: {},
              videos: [],
              imgs: [],
              prizes: [],
              files: [],
              buildCategoryText: '',
              coldModeText: '',
              thermalModeText: '',
              powerCompanyText: '',
              thermalCompanyText: '',
              energyAuditText: '',
              thermalIndexText: '',
          }
        },
        computed: {
            ...mapGetters(['dtBuildCategory', 'dtBuildState', 'dtColdMode', 'dtThermalMode', 'dtPowerCompany', 'dtThermalCompany', 'dtEnergyAudit', 'dtThermalIndex']),
            coverUrl() {
                return function (str) {
                    return str?str.split(',')[0]: ''
                }
            }
        },
        filters: {
            formatDate3(str) {
                return formatTime(str,3)
            },
        },
        mounted() {
            this.loadData()
        },
        methods: {
            loadData() {
                const id = this.$route.params.id
                getDetail(id).then(res => {
                    console.log('res:', res)
                    this.dt = res.result
                    this.buildCategoryText = this.dtBuildCategory.find(v => v.value == this.dt.buildCategory).text
                    this.coldModeText = this.dtColdMode.find(v => v.value == this.dt.coldMode).text
                    this.thermalModeText = this.dtThermalMode.find(v => v.value == this.dt.thermalMode).text
                    this.powerCompanyText = this.dtPowerCompany.find(v => v.value == this.dt.powerCompany).text
                    this.thermalCompanyText = this.dtThermalCompany.find(v => v.value == this.dt.thermalCompany).text
                    this.energyAuditText = this.dtEnergyAudit.find(v => v.value == this.dt.energyAudit).text
                    this.thermalIndexText = this.dt.thermalIndex ?? this.dtThermalIndex.find(v => v.value == this.dt.thermalIndex).text
                    if(this.dt.video) {
                        this.dt.video.split(',').forEach(v => {
                            const temp = { url: v, playing: false }
                            this.videos.push(temp)
                        })
                    }
                    if(this.dt.img) {
                        this.dt.img.split(',').forEach(v => {
                            const temp = { url: v }
                            this.imgs.push(temp)
                        })
                    }
                    if(this.dt.prize) {
                        this.loadPrize(this.dt.prize.split(','))
                    }
                    if(this.dt.files) {
                        this.dt.files.split(',').forEach(v => {
                            let n = v.substring(v.lastIndexOf('/')+1,v.lastIndexOf('_'))
                            let temp = { name: n, path: v,tm: this.dt.releaseTime }
                            this.files.push(temp)

                        })
                    }
                })
            },
            loadPrize(ary) {
                getIcons().then(res => {
                    if(res.success) {
                        const tempAry = res.result.records
                        this.prizes = tempAry.filter(v => ary.indexOf(v.id)>-1)
                        console.log('prizes:',this.prizes)
                    }
                })
            },
            handleToPreview(path) {
                this.$ls.set('path', path)
                window.open('/preview','_blank')
            }

        }
    }
</script>

<style scoped lang="less">
    .tecnology-detail {
        .detail-header {
            height: 350px;
            background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/tecnology/dtbg.png");
            background-size: 100% auto;
        }
        .detail-content {
            position: absolute;
            left: 0;
            top: 200px;
            width: 100%;
            box-sizing: border-box;
            .undp-content-wrapper {
                background-color: #FFFFFF;
                box-sizing: border-box;
                padding: 80px 40px 40px;
                .activity-header {
                    display: flex;
                    .cover {
                        width: 447px;
                        height: 250px;
                        background-size: 100% 100%;
                    }
                    .header-content {
                        flex: 1;
                        padding: 40px 0 40px 50px;
                        box-sizing: border-box;
                        border-bottom: none;
                        position: relative;
                        display: flex;
                        flex-wrap: wrap;
                        .header-wrapper {
                            margin-bottom: 10px;
                            width: calc((100% - 20px) / 2);
                            span:first-of-type {
                                display: inline-block;
                                padding: 0 8px;
                                width: 80px;
                                height: 30px;
                                /* 绿2 */
                                background: #34CD8B;
                                /* 关联领域 */
                                font-size: 16px;
                                font-weight: normal;
                                line-height: 30px;
                                text-transform: uppercase;
                                letter-spacing: 0em;

                                /* White */
                                color: #FFFFFF;
                                margin-right: 20px;
                            }
                        }
                    }
                }
                .jx-wrapper {
                    width: 90%;
                    margin: 0 auto;
                    height: 100px;
                    display: flex;
                    flex-wrap: wrap;
                    padding: 40px 0;
                    .subtitle-wrapper {
                        width: calc((100% - 40px) / 3);
                        margin-bottom: 10px;
                        box-sizing: border-box;
                        &:nth-of-type(3n) {
                            margin-right: 0;
                        }
                        span:first-of-type {
                            display: inline-block;
                            padding: 0 8px;
                            width: 80px;
                            height: 30px;
                            /* 绿2 */
                            background: #34CD8B;
                            /* 关联领域 */
                            font-size: 16px;
                            font-weight: normal;
                            line-height: 30px;
                            text-transform: uppercase;
                            letter-spacing: 0em;

                            /* White */
                            color: #FFFFFF;
                            margin-right: 20px;
                        }
                    }
                }
                .pc-wrapper {
                    padding: 40px 0;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
                    display: flex;
                    .pc-item {
                        width: 447px;
                        text-align: right;
                        span:first-of-type {
                            display: inline-block;
                            width: 117px;
                            height: 54px;
                            /* 绿2 */
                            background: #34CD8B;
                            /* 项目加密 */
                            font-size: 20px;
                            font-weight: 500;
                            line-height: 34px;
                            text-transform: uppercase;
                            letter-spacing: 0em;
                            /* White */
                            color: #FFFFFF;
                            padding: 10px;
                            box-sizing:border-box;
                            text-align: center;
                        }
                        span:last-of-type {
                            display: inline-block;
                            height: 54px;
                            line-height: 54px;
                            /* 灰 */
                            background: #F5F5F5;
                            font-size: 20px;
                            font-weight: normal;
                            line-height: 54px;
                            text-transform: uppercase;
                            letter-spacing: 0em;

                            /* 标题小字 */
                            color: #666666;
                            text-align: center;
                            padding: 0 10px;
                        }
                    }
                    .pc-item:last-of-type {
                        flex: 1;
                        padding-left: 50px;
                        box-sizing: border-box;
                        text-align: left;
                    }
                }
                .module {
                    display: flex;
                    align-items: center;
                    height: 30px;
                    margin-bottom: 40px;
                    span {
                        height: 30px;
                        font-family: SourceHanSansCN-Medium;
                        font-size: 20px;
                        font-weight: 500;
                        letter-spacing: 0em;
                        line-height: 30px;
                        /* 黑色标题 */
                        color: #333333;
                        margin-left: 8px;
                    }
                }
                .content {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                }
                .movies {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                }
                .zl {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                    .zl-list {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        .zl-item {
                            width: calc((100% - 60px) / 2);
                            height: 150px;
                            margin: 0 60px 20px 0;
                            background: #F5F5F5;
                            border: 1px solid rgba(0, 0, 0, 0.08);
                            display: flex;
                            align-items: center;
                            box-sizing: border-box;
                            padding: 0 30px;
                            cursor: pointer;
                            &:nth-of-type(2n) {
                                margin-right: 0;
                            }
                            .zl-cover {
                                margin-right: 30px;
                            }
                            .zl-content {
                                p:first-of-type {
                                    height: 65px;
                                    font-family: SourceHanSansCN-Regular;
                                    font-size: 20px;
                                    font-weight: normal;
                                    line-height: 30px;
                                    letter-spacing: 0em;

                                    /* 文字 */
                                    color: rgba(0, 0, 0, 0.8);
                                }
                                p:last-of-type {
                                    height: 23px;
                                    font-family: SourceHanSansCN-Regular;
                                    font-size: 16px;
                                    font-weight: normal;
                                    letter-spacing: 0em;

                                    /* 灰色 简介类 */
                                    color: #999999;
                                }
                            }
                        }
                    }
                }
                .honer {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                    .honer-content {
                        display: flex;
                        flex-wrap: wrap;
                        .cover {
                            width: calc((100% - 60px) / 4);
                            padding-bottom: 30%;
                            margin: 0 20px 20px 0;
                            background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/tecnology/zs.jpeg");
                            background-size: 100% 100%;
                            &:nth-of-type(4n) {
                                margin-right: 0;
                            }
                        }
                    }
                }
                .organize {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                    .org-wrapper {
                        display: flex;
                        align-items: center;
                        .cover {
                            width: 120px;
                            height: 120px;
                            margin-right: 50px;
                            background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/home/qh.jpeg");
                            background-size: 100% 100%;
                        }
                        .org-content {
                            flex: 1;
                        }
                    }

                }
                .expert {
                    padding: 40px 0;
                    border-bottom: 1px solid #CCCCCC;
                    .expert-content {
                        display: flex;
                        flex-wrap: wrap;
                        .item {
                            width: calc((100% - 120px) / 3);
                            margin: 0 60px 30px 0;
                            border: 1px solid #CCCCCC;
                            box-sizing: border-box;
                            &:nth-of-type(3n) {
                                margin-right: 0;
                            }
                            .cover {
                                width: 100%;
                                padding-bottom: 100%;
                                background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/tecnology/expert.png");
                                background-size: cover;
                            }
                            .expert-wrapper {
                                padding: 20px;
                                box-sizing: border-box;
                                background-color: #F5F5F5;
                                .expert-name {
                                    height: 30px;
                                    font-family: SourceHanSansCN-Regular;
                                    font-size: 20px;
                                    font-weight: normal;
                                    line-height: 30px;
                                    letter-spacing: 0em;
                                    /* 黑色标题 */
                                    color: #333333;
                                    margin-bottom: 15px;
                                }
                                .expert-desc {
                                    height: 52px;
                                    font-family: SourceHanSansCN-Regular;
                                    font-size: 16px;
                                    font-weight: normal;
                                    line-height: 26px;
                                    letter-spacing: 0em;

                                    /* 灰色 简介类 */
                                    color: #999999;
                                }
                            }
                        }
                    }

                }
                .comment {
                    padding: 40px 0;
                    .comment-title {
                        padding-bottom: 40px;
                        p:first-of-type {
                            height: 29px;
                            font-family: SourceHanSansCN-Medium;
                            font-size: 20px;
                            font-weight: 500;
                            letter-spacing: 0em;

                            /* 黑色标题 */
                            color: #333333;
                        }
                        p:last-of-type {
                            height: 20px;
                            font-family: SourceHanSansCN-Regular;
                            font-size: 14px;
                            font-weight: normal;
                            text-transform: uppercase;
                            letter-spacing: 0em;

                            /* 标题小字 */
                            color: #666666;
                        }
                    }
                    .comment-ipt {
                        border-top: 1px solid #CCCCCC;
                        border-bottom: 1px solid #CCCCCC;
                        box-sizing: border-box;
                        padding: 40px 0;
                        textarea {
                            width: 100%;
                            height: 84px;
                            outline: none;
                            /* 灰 */
                            background: #F5F5F5;
                            border: 1px solid rgba(0, 0, 0, 0.08);
                            padding: 20px;
                            box-sizing: border-box;
                        }
                        .btn-comment {
                            width: 80px;
                            height: 32px;
                            line-height: 32px;
                            /* 绿2 */
                            background: #34CD8B;
                            font-family: SourceHanSansCN-Regular;
                            font-size: 16px;
                            font-weight: normal;
                            letter-spacing: 0em;
                            /* White */
                            color: #FFFFFF;
                            border: none;
                            margin-top: 20px;
                        }
                    }
                    .comment-list {
                        padding-top: 20px;
                        .comment-item {
                            height: 60px;
                            display: flex;
                            align-items: center;
                            margin-bottom: 20px;
                            .cover {
                                width: 50px;
                                height: 50px;
                                background-image: url("https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/tecnology/head.png");
                                background-size: 100% 100%;
                                margin-right: 25px;
                            }
                            .comment-content {
                                flex: 1;
                                .top {
                                    span {
                                        &:first-of-type {
                                            height: 30px;
                                            font-family: SourceHanSansCN-Regular;
                                            font-size: 16px;
                                            font-weight: normal;
                                            line-height: 30px;
                                            letter-spacing: 0em;
                                            /* 标题小字 */
                                            color: #666666;
                                            margin-right: 20px;
                                        }
                                        &:nth-of-type(2) {
                                            height: 30px;
                                            font-family: SourceHanSansCN-Regular;
                                            font-size: 14px;
                                            font-weight: normal;
                                            line-height: 30px;
                                            letter-spacing: 0em;

                                            /* 灰色 简介类 */
                                            color: #999999;
                                        }
                                    }
                                }
                                .bottom {
                                    height: 30px;
                                    font-family: SourceHanSansCN-Regular;
                                    font-size: 16px;
                                    font-weight: normal;
                                    line-height: 30px;
                                    letter-spacing: 0em;
                                    /* 标题小字 */
                                    color: #666666;
                                }
                            }
                        }
                    }
                }
            }

        }

    }
</style>