<template>
    <a-form-model ref="form" :model="model" :rules="validatorRules">
        <div class="login-ipt">
            <a-form-model-item required prop="mobile" style="margin-bottom: 0">
                <div class="user-name">
                    <div class="phone-wrapper">
                        <img src="https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/sj.png" height="35" width="23"/>
                    </div>
                    <div class="phone-promt">+86</div>
                    <input type="text" v-model="model.mobile" class="ipt" placeholder="请输入手机号">
                </div>
            </a-form-model-item>
            <a-form-model-item required prop="captcha" style="margin-bottom: 0">
                <div class="user-name">
                    <div class="phone-wrapper">
                        <img src="https://undpweb.oss-cn-hangzhou.aliyuncs.com/system/assets/client/yzm.png" height="34" width="38"/>
                    </div>
                    <input type="text" v-model="model.captcha" class="ipt pwd" placeholder="请输入验证码" style="width:200px">
                    <!--            <input type="button"-->
                    <!--                   :disabled="state.smsSendBtn"-->
                    <!--                   @click.stop.prevent="getCaptcha"-->
                    <!--                   style="width: 100%;height: 40px"-->
                    <!--                   v-text="!state.smsSendBtn && '获取验证码' || (state.time+' s')" />-->
                    <a
                            href="#"
                            class="yzm"
                            @click.stop.prevent="getCaptcha"
                            :disabled="state.smsSendBtn"
                            v-text="state.smsSendBtn?(state.time+' s'): '获取验证码'"
                    ></a>
                </div>
            </a-form-model-item>


        </div>
    </a-form-model>

</template>

<script>
    import api from '@/api/http'
    import { mapActions } from 'vuex'

    export default {
        name: 'LoginPhone',
        data(){
            return {
                model:{
                    mobile: '',
                    captcha: ''
                },
                //手机号登录用
                state: {
                    time: 60,
                    smsSendBtn: false,
                },
                validatorRules:{
                    mobile: [
                        { required: true, message: '请输入手机号码!' },
                        { validator: this.validateMobile }
                    ],
                    captcha: [{
                        required: true, message: '请输入验证码!'
                    }]
                }

            }
        },
        methods:{
            ...mapActions(['PhoneLogin']),
            handleLogin(){
                // let loginParams = {
                //     mobile: this.model.mobile,
                //     captcha: this.model.captcha,
                // }
                // console.log("登录参数", loginParams)
                // this.PhoneLogin(loginParams).then((res) => {
                //     this.$emit('success', res.result)
                // }).catch((err) => {
                //     this.$emit('fail', err)
                // });
                this.validateFields([ 'mobile', 'captcha' ], (err) => {
                    if (!err) {
                        let loginParams = {
                            mobile: this.model.mobile,
                            captcha: this.model.captcha,
                        }
                        console.log("登录参数", loginParams)
                        this.PhoneLogin(loginParams).then((res) => {
                            console.log('no err', res)
                            this.openNotificationWithIcon('success',res.message)
                            this.$router.push({name: 'home'})
                            this.$emit('success', res.result)
                        }).catch((err) => {
                            this.$emit('fail', err)
                        });

                    }else{
                        console.log('err')
                        this.$emit('validateFail')
                    }
                })
            },
            // 校验手机号
            validateMobile(rule,value,callback){
                if (!value || new RegExp(/^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/).test(value)){
                    callback();
                }else{
                    callback("您的手机号码格式不正确!");
                }
            },
            //获取验证码
            getCaptcha (e) {
                e.preventDefault();
                let that = this;
                that.validateFields([ 'mobile' ], (err) => {
                    console.log('err:', err)
                    if (!err) {
                        that.state.smsSendBtn = true;
                        let interval = window.setInterval(() => {
                            if (that.state.time-- <= 0) {
                                that.state.time = 60;
                                that.state.smsSendBtn = false;
                                window.clearInterval(interval);
                            }
                        }, 1000);

                        const hide = that.$message.loading('验证码发送中..', 0);
                        let smsParams = {};
                        smsParams.mobile=that.model.mobile;
                        smsParams.smsmode="0";
                        api.post("/sys/sms",smsParams)
                            .then(res => {
                                if(!res.success){
                                    setTimeout(hide, 0);
                                    that.cmsFailed(res.message);
                                }
                                console.log(res);
                                setTimeout(hide, 500);
                            })
                            .catch(err => {
                                setTimeout(hide, 1);
                                clearInterval(interval);
                                that.state.time = 60;
                                that.state.smsSendBtn = false;
                                that.requestFailed(err);
                            });
                    }
                    }
                );
                // that.state.smsSendBtn = true;
                // let interval = window.setInterval(() => {
                //     if (that.state.time-- <= 0) {
                //         that.state.time = 60;
                //         that.state.smsSendBtn = false;
                //         window.clearInterval(interval);
                //     }
                // }, 1000);
                //
                // const hide = that.$message.loading('验证码发送中..', 0);
                // let smsParams = {};
                // smsParams.mobile=that.model.mobile;
                // smsParams.smsmode="0";
                // api.post("/sys/sms",smsParams)
                //     .then(res => {
                //         if(!res.success){
                //             setTimeout(hide, 0);
                //             that.cmsFailed(res.message);
                //         }
                //         console.log(res);
                //         setTimeout(hide, 500);
                //     })
                //     .catch(err => {
                //         setTimeout(hide, 1);
                //         clearInterval(interval);
                //         that.state.time = 60;
                //         that.state.smsSendBtn = false;
                //         that.requestFailed(err);
                //     });
            },
            cmsFailed(err){
                this.$notification[ 'error' ]({
                    message: '获取验证码失败',
                    description:err,
                    duration: 4,
                });
            },
            /**
             * 验证字段
             * @param arr
             * @param callback
             */
            validateFields(arr, callback){
                let promiseArray = []
                for(let item of arr){
                    let p = new Promise((resolve, reject) => {
                        this.$refs['form'].validateField(item, (err)=>{
                            if(!err){
                                resolve();
                            }else{
                                reject(err);
                            }
                        })
                    });
                    promiseArray.push(p)
                }
                Promise.all(promiseArray).then(()=>{
                    callback()
                }).catch(err=>{
                    callback(err)
                })
            },
            openNotificationWithIcon(type,msg) {
                this.$notification[type]({
                    message: '登录提示',
                    description: msg
                });
            },

        }

    }

</script>

<style scoped lang="less">
    .login-ipt {
        .user-name {
            /* 矩形 419 */
            border-radius: 4px;
            /* White */
            background: #FFFFFF;
            /* 灰色 简介类 */
            border: 1px solid #999999;
            margin-bottom: 20px;
            display: flex;
            height: 50px;
            align-items: center;
            .phone-wrapper {
                width: 50px;
                height: 100%;
                background-color: #CCCCCC;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

            }
            .phone-promt {
                width: 40px;
                text-align: center;
                line-height: 50px;

            }
            .ipt {
                flex: 1;
                border: none;
                outline: none;
                font-size: 16px;
                color: #999999;
                box-sizing: border-box;
                padding-left: 20px;

            }
            .yzm {
                width: 80px;
                font-family: SourceHanSansCN-Regular;
                font-size: 16px;
                font-weight: normal;
                letter-spacing: 0em;

                /* 黑色标题 */
                color: #333333;
                margin-right: 10px;
            }
        }
    }
</style>