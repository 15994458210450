import api from '@/api/http'

export function getBuildingList({ pageNo, pageSize, buildCategory, buildState } = {}) {
    return api.get(`/undp/undpBuild/list?column=createTime&order=desc&pageNo=${pageNo}&pageSize=${pageSize}`,{buildCategory,buildState})
}
export function getDetail(id) {
    return api.get(`/undp/undpBuild/queryById?id=${id}`)
}
export function getIcons() {
    return api.get(`/undp/undpIcon/list?iconType=1`)
}

