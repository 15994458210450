import request from '../request'

export default {
    /**
     * @param {String} url
     * @param {Object} data
     * @returns Promise
     */
    post(url, data) {
        return new Promise((resolve, reject) => {
            request({
                method: 'post',
                url,
                data: data,
            })
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                });
        })
    },
    delete(url, data) {
        return new Promise((resolve, reject) => {
            request({
                method: 'delete',
                url,
                data: data,
            })
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                });
        })
    },

    get(url, data) {
        return new Promise((resolve, reject) => {
            request({
                method: 'get',
                url,
                params: data,
            })
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
};
