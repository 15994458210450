import api from '@/api/http'

export function getTecnologyList({ pageNo, pageSize, departField, dataType, dataFormat, innovateName } = {}) {
    return api.get('/undp/undpInnovate/list',{ pageNo, pageSize, departField, dataType, dataFormat, innovateName,column: 'createTime',order: 'desc' })
}
export function getDetail(id) {
    return api.get('/undp/undpInnovate/queryById',{ id })
}

export function getAllDictItems() {
    return api.get('/sys/dict/queryAllDictItems')
}

