import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Storage from 'vue-ls'

import defaultSettins from "@/defaultSettins"
Vue.use(Storage,defaultSettins.storageOptions)

// Vue.use(Antd)
import Antd, { version } from 'ant-design-vue'
console.log('ant-design-vue version:', version)
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd)


import './assets/common.css'

// import VueAwesomeSwiper from 'vue-awesome-swiper'
// // import style
// import 'swiper/swiper.less'
//
// Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

import Area from '@/common/util/Area.js'
Vue.prototype.$Area = new Area()

Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
