import api from '@/api/http'

export function getList({ pageNo, pageSize, projectDepart, projectState, projectType } = {}) {
    return api.get(`/undp/undpProject/list?projectState=${projectState}&projectType=${projectType}&projectDepart=${projectDepart}&pageNo=${pageNo}&pageSize=${pageSize}`)
}
export function getDetail(id) {
    return api.get(`/undp/undpProject/queryById?id=${id}`)
}
export function getComments({ pageNo, pageSize, projectId } = {}) {
    return api.get(`/undp/undpProjectComment/list?pageNo=${pageNo}&pageSize=${pageSize}&projectId=${projectId}&column=createTime&order=desc&commentStatus=1`)
}
export function addComment({ projectId, likes, content, commentStatus } = {}) {
    return api.post('/undp/undpProjectComment/add', { projectId, likes, content, commentStatus  })
}
export function deleteComment(id) {
    return api.delete(`/undp/undpProjectComment/delete?id=${id}`)
}
